import React from "react";
import {
  fetchLocalStorageProducts,
  fetchCart,
  removeProductFromCart,
  fetchCustomShippingMethods,
  fetchCartProducts,
  hideCart,
  setSelectedShippingAddress,
  setUpdateProductDetails,
  setLoaderCart,
  createEmptyCart,
  removeGiftCardDetailsByCartItemId,
  setEmptyCart,
  setLoader,
  clearGiftCardDetails,
} from "../actions";
import { connect } from "react-redux";
import history from "../history";
import { isTap2Buy, isGiftGuru, convertPrice } from "../helper/common"

class Cart extends React.Component {
  componentDidMount = () => {
    if (this.props.auth.is_signed_in) {
      this.props.fetchLocalStorageProducts();
    }
  };

  renderCartProducts = () => {
    const country_code = localStorage.getItem('country_code') || 'AU';
    const currency_rate = localStorage.getItem('currency_rate')
      ? JSON.parse(localStorage.getItem('currency_rate'))
      : {};
    const currencySymbol = country_code === 'AU' ? 'AUD' : 'USD';
    const keys = Object.keys(this.props.localstorage_products)
    return keys.map((key, index) => {
      const v = this.props.localstorage_products[key]
      const price_dec = parseFloat(v.price);
      const price = convertPrice(price_dec, country_code, currency_rate);
      let image =
        v.images.length > 0
          ? v.images[0]
          : "https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/Magento_Catalog/images/product/placeholder/thumbnail.jpg";

      return (
        <div key={key} className="cart-product">
          <div className="clear">
            <div className="checkout-left-col">
              <div className="checkout-bold-left">{v.name}</div>
              <div className="checkout-bold">${price} {currencySymbol}</div>
              <div className="remove-product-parent">
                <button
                  style={{
                    background: "#bfdd00",
                    color: "#022a1e",
                    fontSize: "11px",
                    textAlign: "center",
                    padding: "6px 10px",
                    borderRadius: "39px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    fontWeight: "400",
                    width: "70px"
                  }}
                  onClick={() => {
                    history.push(`/giftcard/add/${key}`);
                    this.props.hideCart();
                  }}
                >
                  Change
                </button>
                <button
                  className="remove-product"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const updatedGiftCards = {...this.props.localstorage_products};
                    delete updatedGiftCards[key];
                    localStorage.setItem('gift_card_details', JSON.stringify(updatedGiftCards));
                    this.props.fetchLocalStorageProducts();
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
            <div className="checkout-right-col">
              <img
                className={
                  v.is_giftcard ? "checkout-giftcard-image" : "checkout-image"
                }
                src={image}
              />
            </div>
          </div>
        </div>
      );
    })
  };

  renderCartWithProducts = () => {
    let total = 0;

    let shipping_amount = 0;
    if (this.props.shipping_method) {
      shipping_amount = this.props.shipping_method.amount;
      total += shipping_amount;
      shipping_amount = shipping_amount.toFixed(2);
    }

    Object.keys(this.props.localstorage_products).forEach((v, i, a) => {
      const price_dec = parseFloat(v.price);
      total += v.qty * price_dec;
    });
    let total_amount = total.toFixed(2);

    return (
      <React.Fragment>
        <div id="cart-popup-heading">Your Cart</div>
        <div id="cart-products">{this.renderCartProducts()}</div>
        <div id="cart-buttons">
          {isTap2Buy() ? (
            <>
              <button
                id="cart-popup-checkout"
                onClick={() => {
                  history.push("/checkout");
                  this.props.hideCart();
                }}
              >
                Checkout
              </button>
              <button
                id="cart-popup-keep-shopping"
                onClick={() => {
                  history.push("/browse");
                  this.props.hideCart();
                }}
              >
                Keep Shopping
              </button>
            </>
          ) : (
            <button
              id="cart-popup-checkout"
              style={{display: 'block', margin: '0 auto', float: 'none'}}
              onClick={() => {
                history.push("/checkout");
                this.props.hideCart();
              }}
            >
              Checkout
            </button>
          )}
        </div>
        <div id="cart-popup-close-window-container">
          <div id="cart-popup-close-window-parent">
            <div
              id="cart-popup-close-window"
              onClick={() => {
                this.props.hideCart();
              }}
            >
              Close Window
            </div>
            <div
              id="cart-popup-close-window"
              onClick={() => {
                localStorage.removeItem('gift_card_details');
                this.props.fetchLocalStorageProducts();
              }}
            >
              Clear Cart
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    let cart_products = null;
    let cart_empty = null;
    if (Object.keys(this.props.localstorage_products).length > 0) {
      cart_products = this.renderCartWithProducts();
    } else {
      cart_empty = <div id="cart-popup-empty">Cart is empty</div>;
    }

    let cart_content = null;
    if (this.props.loader.cart.show) {
      cart_content = (
        <React.Fragment>
          <div id="cart-popup-heading">Your Cart</div>
          <div id="loader-cart-parent">
            <div id="loader-cart">
              <img src="/tap2buy-loader.gif" />
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      cart_content = (
        <React.Fragment>
          {cart_products}
          {cart_empty}
        </React.Fragment>
      );
    }

    return (
      <div id="cart-mask">
        <div id="cart-popup">
          <div id="cart-popup-x-parent">
            <span
              id="cart-popup-x"
              onClick={() => {
                this.props.hideCart();
              }}
            >
              x
            </span>
          </div>
          {cart_content}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    shipping_method: state.shipping_method.shipping_method,
    localstorage_products: state.localstorage_products.localstorage_products,
    shipping_addresses: state.shipping_addresses,
    auth: state.auth,
    loader: state.loader,
    gift_card_details: state.gift_card_details.gift_card_details,
  };
};

export default connect(mapStateToProps, {
  fetchLocalStorageProducts,
  fetchCart,
  removeProductFromCart,
  fetchCustomShippingMethods,
  fetchCartProducts,
  hideCart,
  setSelectedShippingAddress,
  setUpdateProductDetails,
  setLoaderCart,
  createEmptyCart,
  removeGiftCardDetailsByCartItemId,
  setEmptyCart,
  setLoader,
  clearGiftCardDetails,
})(Cart);
