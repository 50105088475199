import React from "react";
import { fetchCart, createEmptyCart, fetchCartProducts, fetchProduct, saveGiftCardDetails } from "../../actions";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import requireAuth from "../requireAuth"; 
import history from "../../history";
import CONSTANTS from "../../constants"
import GiftCardForm from "../GiftCardForm";

class GiftCardAddPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      product_id: null,
      configurable_product_sku: null,
      product_name: null,
      product_price: null,
      product_image: null,
      product_uuid: null,
      product: {},
      products: {},
      recipient_firstname: "",
      recipient_lastname: "",
      recipient_mobile: "",
      recipient_message: "",
      purchaser_from: "",
      text_content: "",
    }
  }

  componentDidMount = () => {
    const product_uuid = this.props.match.params.productuuid
    const existingProductsJSON = localStorage.gift_card_details;
    const existingProducts = existingProductsJSON ? JSON.parse(existingProductsJSON) : {};
    const existingProduct = existingProducts[product_uuid]
    const product_id = existingProduct.id
    const product_name = existingProduct.name
    const product_price = existingProduct.price
    const product_image = existingProduct.images[0]
    const configurable_product_sku = existingProduct.sku
    const recipient_firstname = existingProduct.recipient_firstname ? existingProduct.recipient_firstname : ''
    const recipient_lastname = existingProduct.recipient_lastname ? existingProduct.recipient_lastname : ''
    const recipient_mobile = existingProduct.recipient_mobile ? existingProduct.recipient_mobile : ''
    const recipient_message = existingProduct.recipient_message ? existingProduct.recipient_message : ''

    const customerJSON = localStorage.customer;
    const customer = customerJSON ? JSON.parse(customerJSON) : {};
    const purchaser_from = customer.firstname
    const text_content = existingProduct.text_content ? existingProduct.text_content.replace(/(\n)?<giftcard>/g, '') : `Congratulations, ${purchaser_from} has sent you a GiftCard.`
    const canFinish = existingProduct.mms_template_id && existingProduct.price && existingProduct.scheduled_delivery ? true : false

    this.setState({ product_id: product_id,
      configurable_product_sku: configurable_product_sku,
      product_name: product_name,
      product_price: product_price,
      product_image: product_image,
      product_uuid: product_uuid,
      products: existingProducts,
      recipient_firstname: recipient_firstname,
      recipient_lastname: recipient_lastname,
      recipient_mobile: recipient_mobile,
      recipient_message: recipient_message,
      purchaser_from: purchaser_from,
      text_content: text_content,
      canFinish: canFinish
    })
  }

  render() {
    return (
      <div id="page-gift-card-recipient-details">
        <h1 className="h1" style={{marginBottom: 19}}>Who are you sending it to?</h1>
        {this.state.product_id && <GiftCardForm
          product_id={this.state.product_id}
          product_uuid={this.state.product_uuid}
          products={this.state.products}
          configurable_product_sku={this.state.configurable_product_sku}
          product_name={this.state.product_name}
          product_price={this.state.product_price}
          product_image={this.state.product_image}
          recipient_firstname={this.state.recipient_firstname}
          recipient_lastname={this.state.recipient_lastname}
          recipient_mobile={this.state.recipient_mobile}
          recipient_message={this.state.recipient_message}
          purchaser_from={this.state.purchaser_from}
          text_content={this.state.text_content}
          canFinish={this.state.canFinish}
          handleAction={(data) => {
            const existingProductsJSON = localStorage.gift_card_details;
            const existingProducts = existingProductsJSON ? JSON.parse(existingProductsJSON) : {};
            const existingProduct = existingProducts[this.state.product_uuid]
            existingProduct.recipient_firstname = data.recipient_firstname
            existingProduct.recipient_lastname = data.recipient_lastname
            existingProduct.recipient_message = data.recipient_message
            existingProduct.recipient_mobile = data.recipient_mobile
            existingProduct.text_content = data.text_content
            localStorage.gift_card_details = JSON.stringify(existingProducts);
            history.push(`/giftcard/mmstemplates/${this.state.product_id}/${this.state.product_uuid}`)
          }}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    cart: state.cart.cart,
    cart_products: state.cart_products.cart_products,
    gift_card_details: state.gift_card_details.gift_card_details
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchCart, createEmptyCart, fetchCartProducts, fetchProduct, saveGiftCardDetails }
  )(GiftCardAddPage) 
);
