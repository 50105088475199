import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  setCart,
  fetchCart,
  setLoader,
  fetchCartProducts,
  showMobilePopup,
  triggeredMobilePopup,
  fetchProduct,
  fetchSeller
} from "../actions";
import history from "../history";
import { convertPrice, getWithExpiry } from "../helper/common";

class ProductItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
    };

    this.productImage = React.createRef();
  }

  updateDimensions = () => {
    const width = this.productImage.current.offsetWidth;

    let temp_height = 0;
    if (!this.props.product.is_giftcard) {
      temp_height = width * 1.2;
    } else if (this.props.product.is_giftcard) {
      temp_height = width * 0.62;
    }

    this.setState({ height: temp_height });
  };

  componentDidMount() {
    this.updateDimensions();

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  addProductToCart = (product) => {
    if (product.type_id == "configurable") {
      history.push(`/product/${product.id}`);
    } else {
      this.props.setLoader(true, "Adding Product to Cart...");
      const product_data = {
        sku: product.sku,
        qty: 1,
      };
      this.props.setCart(product_data, this.props.cart.id, () => {
        this.props.fetchCart();
        this.props.fetchCartProducts(() => {
          this.props.setLoader(false, null);
        });
      });
    }
  };

  renderPrice = () => {
    const country_code = localStorage.getItem('country_code') || 'AU';
    const currency_rate = localStorage.getItem('currency_rate')
      ? JSON.parse(localStorage.getItem('currency_rate'))
      : {};
    const currencySymbol = country_code === 'AU' ? 'AUD' : 'USD';
    const product = this.props.product;
    let price = parseFloat(product.price);
    price = convertPrice(price, country_code, currency_rate);

    return (
      <div className="product-price" style={{ position: "relative", top: 11 }}>
        {this.props.product.type_id === "configurable"
          ? `From $${price} ${currencySymbol}`
          : `$${price} ${currencySymbol}`}
      </div>
    );
  };

  renderButton = () => {
    return this.props.product.qty > 0 ? (
      <div className="product-add-to-cart-parent">
        <button
          className="product-add-to-cart"
          onClick={() => {
            const token = getWithExpiry("token_ttl");
            console.log('Check token with ttl', token)
            if (token) {
              this.addProductToCart(this.props.product);
            } else {
              this.props.triggeredMobilePopup({
                pathname: null,
              });
              this.props.setLoader(true, "Fetching Product...")
              this.props.fetchProduct(this.props.product.id, () => {
                this.props.fetchSeller(this.props.product.id, () => {
                  this.props.setLoader(false, null)
                  this.props.showMobilePopup();
                })
              })
            }
          }}
          style={{ float: "right" }}
        >
          {this.props.product.is_giftcard ? "Buy Now" : "Add To Cart"}
        </button>
      </div>
    ) : (
      <div className="product-out-of-stock">Out of Stock</div>
    );
  };

  render() {
    const product = this.props.product;
    let price = parseFloat(product.price);
    price = price.toFixed(2);

    return (
      <div className="product-tile">
        <Link to={`/product/${product.id}`}>
          <div className="product-image-wrapper">
            <img
              ref={this.productImage}
              className="product-image-photo"
              src={product.image}
              style={{ height: this.state.height }}
            />
          </div>
        </Link>
        <Link to={`/product/${product.id}`}>
          <div
            className="product-name"
            style={{
              height: this.props.product.is_giftcard
                ? "unset"
                : !this.props.product.is_giftcard
                ? "40px"
                : "40px",
            }}
          >
            {product.name}
          </div>
        </Link>
        <div>
          <div style={{ float: "left", width: "50%" }}>
            {this.renderPrice()}
          </div>
          <div style={{ float: "left", width: "50%" }}>
            {this.renderButton()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  setCart,
  fetchCart,
  setLoader,
  fetchCartProducts,
  showMobilePopup,
  triggeredMobilePopup,
  fetchProduct,
  fetchSeller
})(ProductItem);
