export const isTap2Buy = () => {
  return process.env.REACT_APP_TAP2BUY === "true"
}

export const isTap2BuyLive = () => {
  return process.env.REACT_APP_TAP2BUY_LIVE === "true"
}

export const isTap2BuyTest = () => {
  return process.env.REACT_APP_TAP2BUY_TEST === "true"
}

export const isGiftGuru = () => {
  return process.env.REACT_APP_GIFTGURU === "true"
}

export const isGiftGuruLive = () => {
  return process.env.REACT_APP_GIFTGURU_LIVE === "true"
}

export const isGiftGuruTest = () => {
  return process.env.REACT_APP_GIFTGURU_TEST === "true"
}

export const convertPrice = (priceInAUD, country_code, currency_rate) => {
  if (country_code === 'AU') {
    return parseFloat(priceInAUD).toFixed(2); // No conversion needed
  } else if (country_code === 'US') {
    const usdRate = parseFloat(currency_rate['USD']);
    return (parseFloat(priceInAUD) / usdRate).toFixed(2);
  } else {
    // Add additional conversion logic for other currencies if needed
    return parseFloat(priceInAUD).toFixed(2);
  }
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Get SMS length
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

var charset7bit = [
  "@",
  "£",
  "$",
  "¥",
  "è",
  "é",
  "ù",
  "ì",
  "ò",
  "Ç",
  "Ø",
  "ø",
  "Å",
  "å",
  "Δ",
  "_",
  "Φ",
  "Γ",
  "Λ",
  "Ω",
  "Π",
  "Ψ",
  "Σ",
  "Θ",
  "Ξ",
  "Æ",
  "æ",
  "ß",
  "É",
  " ",
  "!",
  '"',
  "#",
  "¤",
  "%",
  "&",
  "'",
  "(",
  ")",
  "*",
  "+",
  ",",
  "-",
  ".",
  "/",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  ":",
  ";",
  "<",
  "=",
  ">",
  "?",
  "¡",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "Ä",
  "Ö",
  "Ñ",
  "Ü",
  "§",
  "¿",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "ä",
  "ö",
  "ñ",
  "ü",
  "à",
];

var charset7bitext = [
  "\r",
  "\n",
  "\f",
  "^",
  "{",
  "}",
  "\\",
  "[",
  "~",
  "]",
  "|",
  "€",
];


// UDH
// UDH length: 0x05
// UDH header - Multipart SMS: 0x00
// Multipart SMS - UDH length: 0x03
// Multipart SMS - Unique ID: 0x0a
// Multipart SMS - Total number of parts: generated
// Multipart SMS - Part order: generated
function getUHDChars(parts, part) {
  var udh = ["0x05", "0x00", "0x03", "0x0a"];
  if (parts < 15) {
    udh.push("0x0" + parts.toString(16));
  } else {
    udh.push("0x" + parts.toString(16));
  }
  if (part < 15) {
    udh.push("0x0" + part.toString(16));
  } else {
    udh.push("0x" + part.toString(16));
  }
  return udh;
}

function getSMSChar(chr, coding) {
  let ret = "";
  let clss = "";
  if (coding == "7bit") {
    clss = "c7b";
    if (charset7bitext.indexOf(chr) >= 0) {
      // ret = '<span className="c7eb">ESC</span>';
    }
  }
  if (coding == "16bit") {
    clss = "c16b";
    if (charset7bit.indexOf(chr) == -1 && charset7bitext.indexOf(chr) == -1) {
      clss = clss + " c16bf";
    }
  }
  if (chr == " ") return ret + "Spc";
  if (chr == "\n") return ret + "LF";
  if (chr == "\r") return ret + "CR";
  return ret + chr;
}

function isGSMAlphabet(text) {
  var regexp = new RegExp(
    "^[A-Za-z0-9 \\r\\n@Â£$Â¥Ã¨Ã©Ã¹Ã¬Ã²Ã‡Ã˜Ã¸Ã…Ã¥\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÃ†Ã¦ÃŸÃ‰!\"#$%&'()*+,\\-./:;<=>?Â¡Ã„Ã–Ã‘ÃœÂ§Â¿Ã¤Ã¶Ã±Ã¼Ã ^{}\\\\\\[~\\]|\u20AC]*$"
  );

  return regexp.test(text);
}

export function getSMSLength(str, additional = 0, use_optout = null) {
  var characters = [];
  var count = 0;
  var content = str;
  var chars_arr = content.split("");
  var coding = "7bit";
  var parts = 1;
  var part = 1;
  var chars_used = 0;
  var chars_sms = 160;
  for (let i = 0; i < chars_arr.length; i++) {
    if (charset7bit.indexOf(chars_arr[i]) >= 0) {
      chars_used = chars_used + 1;
    } else if (charset7bitext.indexOf(chars_arr[i]) >= 0) {
      chars_used = chars_used + 2;
    } else {
      coding = "16bit";
      chars_used = chars_arr.length;
      break;
    }
  }

  var newlines = (str.match(/\n/g) || []).length;
  chars_used -= newlines;

  if (coding == "7bit") {
    if (chars_used > 160) {
      var parts = Math.ceil(chars_used / 153);
      var part_chars_used = 7;
      chars_sms = 153;
      characters = [
        ...characters,
        ...getUHDChars(parts, part)
      ];
      for (let i = 0; i < chars_arr.length; i++) {
        if (part_chars_used + 1 > 160) {
          part = part + 1;
          part_chars_used = 7;
          characters = [
            ...characters,
            ...getUHDChars(parts, part)
          ];
        }
        if (
          charset7bitext.indexOf(chars_arr[i]) >= 0 &&
          part_chars_used + 2 > 160 &&
          chars_arr[i] !== "\n"
        ) {
          part = part + 1;
          part_chars_used = 7;
          characters = [
            ...characters,
            ...getUHDChars(parts, part)
          ];
        }
        characters.push(getSMSChar(chars_arr[i], coding));
        if (charset7bitext.indexOf(chars_arr[i]) == -1) {
          part_chars_used = part_chars_used + 1;
        } else if (chars_arr[i] === "\n") {
          part_chars_used = part_chars_used + 1;
        } else {
          part_chars_used = part_chars_used + 2;
        }
      }
    } else {
      chars_sms = 160;
      for (let i = 0; i < chars_arr.length; i++) {
        characters.push(getSMSChar(chars_arr[i], coding));
      }
    }
  } else {
    if (chars_used > 70) {
      var parts = Math.ceil(chars_used / 67);
      var part_chars_used = 3;
      chars_sms = 67;
      characters = [
        ...characters,
        ...getUHDChars(parts, part)
      ];
      for (let i = 0; i < chars_arr.length; i++) {
        if (part_chars_used + 1 > 70) {
          part = part + 1;
          characters = [
            ...characters,
            ...getUHDChars(parts, part)
          ];
          part_chars_used = 3;
        }
        characters.push(getSMSChar(chars_arr[i], coding));
        part_chars_used = part_chars_used + 1;
      }
    } else {
      chars_sms = 70;
      for (let i = 0; i < chars_arr.length; i++) {
        characters.push(getSMSChar(chars_arr[i], coding));
      }
    }
  }

  let encoding = null;
  if (coding == "7bit") {
    encoding = "7bit";
  } else { 
    encoding = "Unicode";
  }

  count = chars_used;

  // minus newlines
  var newlines = (str.match(/\n/g) || []).length;
  count += newlines;
  chars_used += newlines;

  count = count + additional;

  // special
  var special = false;
  for (let i = 0; i < str.length; i++) {
    if (!isGSMAlphabet(str[i])) {
      special = true;
    }
  }

  return {
    chars_sms: chars_sms,
    chars_used: chars_used,
    parts: parts,
    characters: characters,
    encoding: encoding,
    count: count,
    special: special,
  };
}