import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import CONSTANTS from "../constants";
import { fetchProduct, inputValidation, clearValidation } from "../actions";
import { getSMSLength, convertPrice } from "../helper/common";
import history from "../history";

class GiftCardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input_validation: {},
      product_name: null,
      gift_card_price: null,
      product_image: null,
      sms_length: 0,
      sms_message: "",
      recipient_firstname: "",
      recipient_lastname: "",
      recipient_mobile: "",
      recipient_message: "",
      text_content: "",
      textcontent_length: 0,
      textcontent_message: ""
    };
  }

  getSMSLength(str) {
    const data = getSMSLength(str)
    console.log('data', data)
    this.setState({ sms_length: data.count, sms_message: str })
  }

  getTextLength(str) {
    const data = getSMSLength(str)
    console.log('text', data)
    this.setState({ textcontent_length: data.count, textcontent_message: str })
  }

  componentDidMount = () => {
    this.setState({
      recipient_firstname: this.props.recipient_firstname,
      recipient_lastname: this.props.recipient_lastname,
      recipient_mobile: this.props.recipient_mobile,
      recipient_message: this.props.recipient_message,
      text_content: this.props.text_content
    })
    this.getSMSLength(this.props.recipient_message)
    this.getTextLength(this.props.text_content)
  };

  componentDidUpdate = () => {
    console.log('this.props', this.props)
    console.log('this.props.form_values', this.props.form_values)
    if(this.props.form_values && this.props.form_values.recipient_message) {
      const new_message = this.props.form_values.recipient_message
      if(new_message !== this.state.sms_message) {
        this.getSMSLength(new_message)
      }
    }
    if(this.props.form_values && this.props.form_values.text_content) {
      const textcontent_message = this.props.form_values.text_content
      if(textcontent_message !== this.state.textcontent_message) {
        this.getTextLength(textcontent_message)
      }
    }
  }

  renderInput = ({ input, label, type, id, defaultValue = '' }) => {
    return (
      <div>
        <input
          {...input}
          id={id}
          type={type}
          placeholder={label}
          value={defaultValue}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </div>
    );
  };

  renderTextarea = ({ input, label, id, rows, defaultValue }) => {
    return (
      <div>
        <textarea
          {...input}
          id={id}
          placeholder={label}
          value={defaultValue}
          rows={rows}
        ></textarea>
      </div>
    );
  };

  handleSubmit = (formValues) => {
    console.log('formValues', formValues);

    // input_validation
    let input_validation = {};
    if (!this.state.recipient_firstname) {
      input_validation.firstname = ["Please enter a firstname"];
    }

    if (this.state.recipient_firstname && this.state.recipient_firstname.length > 25) {
      input_validation.firstname = ["Firstname must be shorter than 25 characters"];
    }

    if (!this.state.recipient_lastname) {
      input_validation.lastname = ["Please enter a lastname"];
    }

    if (this.state.recipient_lastname && this.state.recipient_lastname.length > 25) {
      input_validation.lastname = ["Lastname must be shorter than 25 characters"];
    }

    if (this.state.text_content && this.state.text_content.length > 200) {
      input_validation.text_content = ["Text message must be shorter than 100 characters"];
    }

    if (!this.state.recipient_mobile) {
      input_validation.mobile = ["Please enter a mobile"];
    }

    if (!this.state.recipient_message) {
      input_validation.message = ["Please enter a message"];
    }

    if (this.state.recipient_message && this.state.recipient_message.length > 50) {
      input_validation.message = ["Message must be shorter than 50 characters"];
    }

    if (!this.state.text_content) {
      input_validation.text_content = ["Please enter text content"];
    }

    console.log('input_validation', input_validation)
    if (Object.keys(input_validation).length > 0) {
      this.props.inputValidation(input_validation)

    } else {
      this.props.clearValidation()

      let gift_card_details = {
        'recipient_firstname' : this.state.recipient_firstname,
        'recipient_lastname' : this.state.recipient_lastname,
        'recipient_mobile' : this.state.recipient_mobile,
        'recipient_message' : this.state.recipient_message,
        'text_content' : this.state.text_content
      };
      gift_card_details.text_content += '\n<giftcard>';

      this.props.handleAction(gift_card_details);
    }
  };

  render() {
    const country_code = localStorage.getItem('country_code') || 'AU';
    const currency_rate = localStorage.getItem('currency_rate')
      ? JSON.parse(localStorage.getItem('currency_rate'))
      : {};
    const currencySymbol = country_code === 'AU' ? 'AUD' : 'USD';
    const price = convertPrice(this.props.product_price, country_code, currency_rate);
    console.log(this.props.input_validation)
    let firstnameMessage = this.props.input_validation.firstname.map(message =>
      <div key={message} className="input-validation">{message}</div>);
    let lastnameMessage = this.props.input_validation.lastname.map(message =>
      <div key={message} className="input-validation">{message}</div>);
    let mobileMessage = this.props.input_validation.mobile.map(message =>
      <div key={message} className="input-validation">{message}</div>);
    let messageMessage = this.props.input_validation.message.map(message =>
      <div key={message} className="input-validation">{message}</div>);
    let textContentMessage = this.props.input_validation.text_content.map(message =>
      <div key={message} className="input-validation">{message}</div>);

    return (
      <form
        className="form"
        id="gift-card-recipient-inputs-form"
        onSubmit={this.props.handleSubmit(this.handleSubmit)}
      >
        <div className="product-gift-card-details-container">
          <div className="gift-card-details-product-info">
            <div className="gift-card-details-left-col">
              <div className="gift-card-details-product-name">
                {this.props.product_name}
              </div>
              <div className="gift-card-details-product-price-container">
                <div className="gift-card-details-product-price-desc">
                  Price:
                </div>
                <div className="gift-card-details-product-price">
                  ${price} {currencySymbol}
                </div>
              </div>
            </div>
            <div className="gift-card-details-right-col">
              <img
                className="gift-card-details-product-image"
                src={this.props.product_image}
              />
            </div>
          </div>
          <div className="gift-card-details-qty-info-container">
            <div className="gift-card-details-qty-info">
              <div className="input-box">
                <Field
                  id={`gift-card-recipient-details-firstname`}
                  name={`recipient_firstname`}
                  component={this.renderInput}
                  label="Firstname"
                  type="text"
                  defaultValue={this.state.recipient_firstname}
                  onChange={(e) => {
                    const value = e.target.value
                    this.setState({ recipient_firstname: value })
                  }}
                />
                {firstnameMessage}
              </div>
              <div className="input-box">
                <Field
                  id={`gift-card-recipient-details-lastname`}
                  name={`recipient_lastname`}
                  component={this.renderInput}
                  label="Lastname"
                  type="text"
                  defaultValue={this.state.recipient_lastname}
                  onChange={(e) => {
                    const value = e.target.value
                    this.setState({ recipient_lastname: value })
                  }}
                />
                {lastnameMessage}
              </div>
              <div className="input-box">
                <Field
                  id={`gift-card-recipient-details-mobile`}
                  name={`recipient_mobile`}
                  component={this.renderInput}
                  label="Mobile"
                  type="text"
                  defaultValue={this.state.recipient_mobile}
                  onChange={(e) => {
                    const value = e.target.value
                    this.setState({ recipient_mobile: value })
                  }}
                />
                {mobileMessage}
              </div>
              <div style={{ marginBottom: 10, fontWeight: 'bold' }}>Image Message:</div>
              <div className="input-box" style={{ marginBottom: 11 }}>
                <Field
                  id={`gift-card-recipient-details-message`}
                  name={`recipient_message`}
                  component={this.renderTextarea}
                  label="Image Message"
                  rows="3"
                  defaultValue={this.state.recipient_message}
                  onChange={(e) => {
                    const value = e.target.value
                    this.setState({ recipient_message: value })
                  }}
                />
                <div style={{
                  fontSize:"14px",
                  position: 'relative',
                  top: '-3px'
                }}>{this.state.sms_length}/50</div>
                {messageMessage}
              </div>
              <div style={{ marginBottom: 5, fontWeight: 'bold' }}>Text Message:</div>
              <div className="input-box" style={{ marginBottom: 11 }}>
                <Field
                  id={`gift-card-recipient-details-text-content`}
                  name={`text_content`}
                  component={this.renderTextarea}
                  label="Text Message"
                  rows="3"
                  defaultValue={this.state.text_content}
                  onChange={(e) => {
                    const value = e.target.value
                    this.setState({ text_content: value })
                  }}
                />
                <div style={{
                  fontSize:"14px",
                  position: 'relative',
                  top: '-3px'
                }}>{this.state.textcontent_length}/200</div>
                {textContentMessage}
              </div>
            </div>
            {this.props.canFinish ? (
              <>
                <div id="show-details-action-buttons" style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div className="show-details-button-parent">
                    <button style={{borderRadius: '30px', width: '170px', fontSize: '16px', fontWeight: '700'}} className="show-details-button">Continue</button>
                  </div>
                  <div className="show-details-button-parent">
                    <button style={{borderRadius: '30px', width: '170px', fontSize: '16px', fontWeight: '700'}} className="show-details-button" 
                    onClick={() => {
                      const existingProducts = this.props.products
                      const existingProduct = existingProducts[this.props.product_uuid]
                      existingProduct.recipient_firstname = this.state.recipient_firstname
                      existingProduct.recipient_lastname = this.state.recipient_lastname
                      existingProduct.recipient_message = this.state.recipient_message
                      existingProduct.recipient_mobile = this.state.recipient_mobile
                      existingProduct.text_content = `${this.state.text_content}\n<giftcard>`
                      localStorage.gift_card_details = JSON.stringify(existingProducts);
                      history.push('/checkout');
                    }}>Finish</button>
                  </div>
                </div>
              </>
              ) :
              (
                <button id="gift-card-details-submit-button">Continue</button>
              )}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product.product,
    input_validation: state.input_validation,
    form_values: getFormValues('gift-card')(state),
  };
};

const giftCardForm = reduxForm({
  form: "gift-card",
})(GiftCardForm);

export default connect(mapStateToProps, { fetchProduct, inputValidation, clearValidation })(giftCardForm);
