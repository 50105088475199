import React from "react";
import {
  fetchCart,
  fetchShippingMethod,
  fetchCartProducts,
  estimateShippingMethod,
  fetchCustomShippingMethods,
  setSelectedShippingAddress,
  setLoaderCheckout,
  setLoader,
  fetchSeller,
  setUpdateProductDetails,
  disableCheckoutButton,
  updateProductQty,
  setCart,
  removeProductFromCart,
  enableCheckoutButton,
  removeGiftCardDetailsByCartItemId,
} from "../actions";
import { connect } from "react-redux";
import history from "../history";
import { Link } from "react-router-dom";
import CONSTANTS from "../constants";
import { convertPrice } from "../helper/common";

window.update_qty_timeouts = {};

class CheckoutCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showQtyButtonsIndex: false
    };
  }
  componentDidMount = () => {
  };

  updateProductQty = (product, qty) => {
    if (window.update_qty_timeouts[product.cart_item]) {
      clearTimeout(window.update_qty_timeouts[product.cart_item]);
    }
    window.update_qty_timeouts[product.cart_item] = setTimeout(() => {
      const quote_id = this.props.cart.id;

      this.props.setLoader(true, "Updating Product Qty...");

      if (product.type_id === "configurable") {
        // color
        let color_option = null;
        product.options.forEach((v) => {
          if (v.attribute_code === "color") {
            color_option = {
              option_id: CONSTANTS.COLOR_OPTION_ID,
              option_value: v.value_index,
            };
          }
        });

        // size
        let size_option = null;
        product.options.forEach((v) => {
          if (v.attribute_code === "size") {
            size_option = {
              option_id: CONSTANTS.SIZE_OPTION_ID,
              option_value: v.value_index,
            };
          }
        });

        // size
        let gift_card_price_option = null;
        product.options.forEach((v) => {
          if (v.attribute_code === "gift_card_price") {
            gift_card_price_option = {
              option_id: CONSTANTS.GIFT_CARD_PRICE_OPTION_ID,
              option_value: v.value_index,
            };
          }
        });

        const configurable_options = {
          color: color_option,
          size: size_option,
          gift_card_price: gift_card_price_option,
        };

        this.props.removeProductFromCart(product.cart_item, () => {
          let configurable_item_options = [];
          for (const [key, value] of Object.entries(configurable_options)) {
            if (value !== null) {
              configurable_item_options.push({
                option_id: value.option_id.toString(),
                option_value: parseInt(value.option_value),
              });
            }
          }

          const product_data = {
            sku: product.sku,
            qty: qty,
            product_type: "configurable",
            product_option: {
              extension_attributes: {
                configurable_item_options: configurable_item_options,
              },
            },
          };
        });
      } else {
        const product_data = {
          item_id: product.cart_item,
          sku: product.sku,
          qty: qty,
        };
      }
    }, 1000);
  };

  renderCartProducts = () => {
    let total = 0;
    let totals = null;
    let totals_added = false;
    const country_code = localStorage.getItem('country_code') || 'AU';
    const currency_rate = localStorage.getItem('currency_rate')
      ? JSON.parse(localStorage.getItem('currency_rate'))
      : {};
    const currencySymbol = country_code === 'AU' ? 'AUD' : 'USD';

    const keys = Object.keys(this.props.all_gift_cards)
    const items = keys.map((key, index) => {
      const v = this.props.all_gift_cards[key]
      const price_dec = parseFloat(v.price);
      total += 1 * price_dec;

      const price = price_dec.toFixed(2);

      let short_desc = v.short_desc ? v.short_desc : null;
      if (short_desc) {
        short_desc = short_desc.substring(3, short_desc.lastIndexOf("<"));
        if (short_desc.length > 20) {
          short_desc = short_desc.substr(0, 20) + "...";
        }
      }

      let image =
        v.images.length > 0
          ? v.images[0]
          : "https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/Magento_Catalog/images/product/placeholder/thumbnail.jpg";



      let total_amount = convertPrice(total, country_code, currency_rate);

      totals = (
        <React.Fragment>
          <div id="checkout-total-row" className="checkout-row-clear">
            <div
              id="checkout-delivery-total-text"
              className="checkout-delivery-total"
            >
              Total
            </div>
            <div
              id="checkout-order-total"
              className="checkout-delivery-total-right"
            >
              ${total_amount} {currencySymbol}
            </div>
          </div>
        </React.Fragment>
      );

      let configurable_options = null;
      if (v.options) {
        configurable_options = v.options.map((v) => {
          return (
            <div key={v.value_index} class="checkout-normal">
              {v.super_attribute_label}: {v.option_title}
            </div>
          );
        });
      }
      if(index === (keys.length - 1))
        return (
          <div key={v.item_id} className="checkout-product">
            <a
              onClick={() => {
                return false;
                const options = v.options ? v.options : [];
                this.props.setUpdateProductDetails({
                  qty: v.qty,
                  options: options,
                  item_id: v.cart_item,
                });
                let product_id = v.id;
                history.push(`/browse`);
                // history.push(`/updateproduct/${product_id}`);
              }}
            >
              <div className="clear">
                <div className="checkout-left-col">
                  <div className="checkout-bold-left">GiftCard{keys.length > 1 && (`s (x${keys.length})`)}</div>
                  {configurable_options}
                  <div className="checkout-bold" style={{ marginBottom: 9 }}>
                    ${total_amount}
                  </div>
                </div>
                <div className="checkout-right-col">
                  <img
                    className={
                      v.is_giftcard ? "checkout-giftcard-image" : "checkout-image"
                    }
                    src={image}
                  />
                </div>
              </div>
            </a>
          </div>
        );
    });

    return (
      <div>
        <div>{items}</div>
        {totals}
      </div>
    );
  };

  render() {
    return <div id="checkout-cart">{this.renderCartProducts()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    shipping_method: state.shipping_method.shipping_method,
    cart_products: state.cart_products.cart_products,
    shipping_addresses: state.shipping_addresses,
    autologin_product_id: state.autologin_product,
    auth: state.auth,
    gift_card_details: state.gift_card_details.gift_card_details,
  };
};

export default connect(mapStateToProps, {
  fetchCart,
  fetchShippingMethod,
  fetchCartProducts,
  estimateShippingMethod,
  fetchCustomShippingMethods,
  setSelectedShippingAddress,
  setLoaderCheckout,
  setLoader,
  fetchSeller,
  setUpdateProductDetails,
  disableCheckoutButton,
  updateProductQty,
  setCart,
  removeProductFromCart,
  enableCheckoutButton,
  removeGiftCardDetailsByCartItemId,
})(CheckoutCart);
