import React from "react";
import {
  fetchProduct,
  fetchCart,
  setCart,
  fetchCartProducts,
  setLoader,
  createEmptyCart,
  fetchSeller,
  setEmptyCart,
  clearGiftCardDetails,
  showUnexpectedErrorPopup,
  deleteGiftCardDetailsByCart,
  fetchLocalStorageProducts,
} from "../../actions";
import { connect } from "react-redux";
import Product from "../Product";
import history from "../../history";
import { isGiftGuru, isTap2Buy } from "../../helper/common";
import uuid from 'react-uuid';

class ProductPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product_id: null,
      not_exists: false,
    };
  }

  componentDidMount = () => {
    console.log('MOUNTING COMPONENT');
    const product_id = this.props.match.params.id;
    this.props.setLoader(true, "Fetching Product...");
    this.props.fetchProduct(product_id, (data) => {
      this.props.fetchSeller(product_id, () => {
        this.props.setLoader(false, null);
      });
    });
    /*
    if (this.props.auth.is_signed_in && isGiftGuru()) {
      this.props.setEmptyCart(() => {
        this.props.createEmptyCart(() => {
          this.props.fetchCart();
          this.props.fetchCartProducts();
          this.props.deleteGiftCardDetailsByCart(this.props.cart.id, () => {
            console.log('Deleted Gift Card Details with Cart ID:');
            console.log(this.props.cart.id);
          });
        })
      });
    }
    */
    if (this.props.auth.is_signed_in && isTap2Buy()) {
      this.props.createEmptyCart(() => {
        this.props.fetchCart();
        this.props.fetchCartProducts();
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.product_id) {
      return { product_id: nextProps.match.params.id };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('UPDATING COMPONENT111');
    if (prevProps.match.params.id !== this.state.product_id) {
      const product_id = this.state.product_id;
      this.props.fetchProduct(product_id);
      /* GD Redesign, store product data first at local
      if (this.props.auth.is_signed_in && isGiftGuru()) {
        this.props.setEmptyCart(() => {
          this.props.createEmptyCart(() => {
            this.props.fetchCart();
            this.props.fetchCartProducts();
            this.props.deleteGiftCardDetailsByCart(this.props.cart.id, () => {
              console.log('Deleted Gift Card Details with Cart ID:');
              console.log(this.props.cart.id);
            });
          })
        });
      }
      */
      if (this.props.auth.is_signed_in && isTap2Buy()) {
        this.props.createEmptyCart(() => {
          this.props.fetchCart();
          this.props.fetchCartProducts();
        });
      }
    }
  }

  handleAddProductToCart = (
    product,
    qty,
    configurable_options,
    configurable_product_sku
  ) => {
    let product_data = {};
    if (configurable_options) {
      let configurable_item_options = [];
      for (const [key, value] of Object.entries(configurable_options)) {
        if (value !== null) {
          configurable_item_options.push({
            option_id: value.option_id.toString(),
            option_value: parseInt(value.option_value),
            price: parseFloat(value.price),
          });
        }
      }

      product_data = {
        id: product.id,
        name: product.name,
        sku: product.sku,
        price: configurable_options.gift_card_price.price,
        images: product.images,
        qty: 1,
        product_type: "configurable",
        product_option: {
          extension_attributes: {
            configurable_item_options: configurable_item_options,
          },
        },
      };

      if (configurable_options && configurable_options.gift_card_price) {
        const existingProductsJSON = localStorage.gift_card_details;
        const existingProducts = existingProductsJSON ? JSON.parse(existingProductsJSON) : {};
        const productuuid = `uuid-` + uuid();
        existingProducts[productuuid] = product_data;
        localStorage.gift_card_details = JSON.stringify(existingProducts);
        this.props.fetchLocalStorageProducts();
        history.push(
          `/giftcard/add/${productuuid}`
        );
      }
    } else {
      product_data = {
        sku: product.sku,
        qty: qty,
        product_type: "simple",
      };

      this.props.setLoader(true, "Adding product to cart...");
      this.props.setCart(product_data, this.props.cart.id, () => {
        this.props.setLoader(false, null);
        console.log("successully added simple product");
        this.props.fetchCart();
        this.props.fetchCartProducts();
      });
    }
  };

  renderProduct = () => {
    return (
      <Product
        update={false}
        product={this.props.product}
        seller={this.props.seller}
        handleProductAction={this.handleAddProductToCart}
      />
    );
  };

  render() {
    return (
      <div id="page-product">
        {this.props.product ? (
          this.renderProduct()
        ) : !this.props.loader.show ? (
          <div id="product-not-exists-message">No Product.</div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    localstorage_products: state.localstorage_products.localstorage_products,
    product: state.product.product,
    cart: state.cart.cart,
    auth: state.auth,
    seller: state.seller.seller,
    loader: state.loader,
  };
};

export default connect(mapStateToProps, {
  fetchProduct,
  fetchCart,
  setEmptyCart,
  clearGiftCardDetails,
  deleteGiftCardDetailsByCart,
  setCart,
  fetchCartProducts,
  setLoader,
  createEmptyCart,
  fetchSeller,
  showUnexpectedErrorPopup,
  fetchLocalStorageProducts,
})(ProductPage);
