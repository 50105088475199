import React from "react";
import { connect } from "react-redux";
import { clearValidation, inputValidation, updateCustomer, fetchCart, setLoader } from "../../actions";
import requireAuth from "../requireAuth";
import AccountNav from "../AccountNav";
import { Field, reduxForm } from "redux-form";
import history from "../../history";

class AccountAddAddressPage extends React.Component {
  constructor(props) {
    super(props);

    this.addressInput = React.createRef();

    this.state = {
      show_address_fields: false,
      regions: [
        {
          id: "569",
          code: "ACT",
          name: "Australian Capital Territory",
        },
        {
          id: "570",
          code: "NSW",
          name: "New South Wales",
        },
        {
          id: "576",
          code: "NT",
          name: "Northern Territory",
        },
        {
          id: "572",
          code: "QLD",
          name: "Queensland",
        },
        {
          id: "573",
          code: "SA",
          name: "South Australia",
        },
        {
          id: "574",
          code: "TAS",
          name: "Tasmania",
        },
        {
          id: "571",
          code: "VIC",
          name: "Victoria",
        },
        {
          id: "575",
          code: "WA",
          name: "Western Australia",
        },
        {
          id: "1",
          code: "AL",
          name: "Alabama"
        },
        {
          id: "2",
          code: "AK",
          name: "Alaska"
        },
        {
          id: "3",
          code: "AS",
          name: "American Samoa"
        },
        {
          id: "4",
          code: "AZ",
          name: "Arizona"
        },
        {
          id: "5",
          code: "AR",
          name: "Arkansas"
        },
        {
          id: "6",
          code: "AE",
          name: "Armed Forces Africa"
        },
        {
          id: "7",
          code: "AA",
          name: "Armed Forces Americas"
        },
        {
          id: "8",
          code: "AE",
          name: "Armed Forces Canada"
        },
        {
          id: "9",
          code: "AE",
          name: "Armed Forces Europe"
        },
        {
          id: "10",
          code: "AE",
          name: "Armed Forces Middle East"
        },
        {
          id: "11",
          code: "AP",
          name: "Armed Forces Pacific"
        },
        {
          id: "12",
          code: "CA",
          name: "California"
        },
        {
          id: "13",
          code: "CO",
          name: "Colorado"
        },
        {
          id: "14",
          code: "CT",
          name: "Connecticut"
        },
        {
          id: "15",
          code: "DE",
          name: "Delaware"
        },
        {
          id: "16",
          code: "DC",
          name: "District of Columbia"
        },
        {
          id: "17",
          code: "FM",
          name: "Federated States Of Micronesia"
        },
        {
          id: "18",
          code: "FL",
          name: "Florida"
        },
        {
          id: "19",
          code: "GA",
          name: "Georgia"
        },
        {
          id: "20",
          code: "GU",
          name: "Guam"
        },
        {
          id: "21",
          code: "HI",
          name: "Hawaii"
        },
        {
          id: "22",
          code: "ID",
          name: "Idaho"
        },
        {
          id: "23",
          code: "IL",
          name: "Illinois"
        },
        {
          id: "24",
          code: "IN",
          name: "Indiana"
        },
        {
          id: "25",
          code: "IA",
          name: "Iowa"
        },
        {
          id: "26",
          code: "KS",
          name: "Kansas"
        },
        {
          id: "27",
          code: "KY",
          name: "Kentucky"
        },
        {
          id: "28",
          code: "LA",
          name: "Louisiana"
        },
        {
          id: "29",
          code: "ME",
          name: "Maine"
        },
        {
          id: "30",
          code: "MH",
          name: "Marshall Islands"
        },
        {
          id: "31",
          code: "MD",
          name: "Maryland"
        },
        {
          id: "32",
          code: "MA",
          name: "Massachusetts"
        },
        {
          id: "33",
          code: "MI",
          name: "Michigan"
        },
        {
          id: "34",
          code: "MN",
          name: "Minnesota"
        },
        {
          id: "35",
          code: "MS",
          name: "Mississippi"
        },
        {
          id: "36",
          code: "MO",
          name: "Missouri"
        },
        {
          id: "37",
          code: "MT",
          name: "Montana"
        },
        {
          id: "38",
          code: "NE",
          name: "Nebraska"
        },
        {
          id: "39",
          code: "NV",
          name: "Nevada"
        },
        {
          id: "40",
          code: "NH",
          name: "New Hampshire"
        },
        {
          id: "41",
          code: "NJ",
          name: "New Jersey"
        },
        {
          id: "42",
          code: "NM",
          name: "New Mexico"
        },
        {
          id: "43",
          code: "NY",
          name: "New York"
        },
        {
          id: "44",
          code: "NC",
          name: "North Carolina"
        },
        {
          id: "45",
          code: "ND",
          name: "North Dakota"
        },
        {
          id: "46",
          code: "MP",
          name: "Northern Mariana Islands"
        },
        {
          id: "47",
          code: "OH",
          name: "Ohio"
        },
        {
          id: "48",
          code: "OK",
          name: "Oklahoma"
        },
        {
          id: "49",
          code: "OR",
          name: "Oregon"
        },
        {
          id: "50",
          code: "PW",
          name: "Palau"
        },
        {
          id: "51",
          code: "PA",
          name: "Pennsylvania"
        },
        {
          id: "52",
          code: "PR",
          name: "Puerto Rico"
        },
        {
          id: "53",
          code: "RI",
          name: "Rhode Island"
        },
        {
          id: "54",
          code: "SC",
          name: "South Carolina"
        },
        {
          id: "55",
          code: "SD",
          name: "South Dakota"
        },
        {
          id: "56",
          code: "TN",
          name: "Tennessee"
        },
        {
          id: "57",
          code: "TX",
          name: "Texas"
        },
        {
          id: "58",
          code: "UT",
          name: "Utah"
        },
        {
          id: "59",
          code: "VT",
          name: "Vermont"
        },
        {
          id: "60",
          code: "VI",
          name: "Virgin Islands"
        },
        {
          id: "61",
          code: "VA",
          name: "Virginia"
        },
        {
          id: "62",
          code: "WA",
          name: "Washington"
        },
        {
          id: "63",
          code: "WV",
          name: "West Virginia"
        },
        {
          id: "64",
          code: "WI",
          name: "Wisconsin"
        },
        {
          id: "65",
          code: "WY",
          name: "Wyoming"
        }


      ],
    };
  }

  componentDidMount = () => {
    this.props.setLoader(true,  null)
    this.props.fetchCart(() => {
      this.props.setLoader(false,  null)
    })
    var autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("google-places-input")
    );
    autocomplete.addListener("place_changed", () => {
      var place = autocomplete.getPlace();
      console.log(place);

      if (place.address_components != undefined) {
        const address = {
          street_address: "",
          suburb: "",
          state: "",
          country: "",
          postcode: "",
        };

        // set country
        place.address_components.forEach(function (value) {
          if (value.types.includes("country")) {
            address.country = value.short_name;
          }
        });

        // set street address
        var street_address = "";
        place.address_components.forEach(function (value) {
          if (value.types.includes("street_number")) {
            street_address += value.long_name;
          }
        });
        street_address += " ";
        place.address_components.forEach(function (value) {
          if (value.types.includes("route")) {
            street_address += value.long_name;
          }
        });
        address.street_address = street_address;

        // set city
        place.address_components.forEach(function (value) {
          if (value.types.includes("locality")) {
            address.suburb = value.long_name;
          }

          if (value.types.includes("postal_town")) {
            address.suburb = value.long_name;
          }

          if (value.types.includes("sublocality")) {
            address.suburb = value.long_name;
          }
        });

        // set state
        place.address_components.forEach(function (value) {
          if (value.types.includes("administrative_area_level_1")) {
            address.state = value.long_name;
          }
        });

        place.address_components.forEach(function (value) {
          if (value.types.includes("postal_code")) {
            address.postcode = value.long_name;
          }
        });

        let region_id = null;
        this.state.regions.forEach((v) => {
          if (v.name === address.state) {
            region_id = v.id;
          }
        });
        const country_id = "AU";
        this.props.change("address", place.formatted_address);
        this.props.change("street_address", address.street_address);
        this.props.change("suburb", address.suburb);
        this.props.change("state", region_id);
        this.props.change("country", country_id);
        this.props.change("postcode", address.postcode);

        this.props.clearValidation();
      } else {
        // show inputs
      }
    });
  };

  renderInput = ({
    input,
    label,
    type,
    defaultValue,
    readonly,
    id,
    addressCallback,
  }) => {
    return (
      <div>
        <input
          {...input}
          id={id}
          type={type}
          placeholder={label}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          ref={addressCallback}
        />
      </div>
    );
  };

  renderSelect = ({ input, isCountries, isRegions, defaultValue }) => {
    let options = null;
    if (isCountries) {
/*      options = (
        <option key="AU" value="AU" defaultValue={defaultValue}>
          Australia
        </option>,
      <option key="US" value="US" defaultValue={defaultValue}>
        United States of America
      </option>
      );*/
      options = [<option key="AU" value="AU" defaultValue={defaultValue}>Australia</option>];
      options.concat([<option key="US" value="US" > United States of America</option>]);
    } else if (isRegions) {
      options = [<option key="enter_state">Enter State</option>];
      let region_options = [];
      if (this.state) {
        region_options = this.state.regions.map((region) => {
          return (
            <option key={region.id} value={region.id}>
              {region.name}
            </option>
          );
        });
      }
      options = options.concat(region_options);
    }

    return (
      <div className="select-wrapper">
        <select {...input} defaultValue={defaultValue}>
          {options}
        </select>
      </div>
    );
  };

  handleSubmit = (formValues) => {
    console.log(formValues);

    //const country_id = "AU";
    //this.props.change("country", country_id);

    let input_validation = {};
    let show_address_fields = false;

    // mobile
    if (!formValues.mobile || formValues.mobile.trim().length === 0) {
      input_validation.mobile = ["Please enter a valid mobile"];
    }
    // fullname
    if (
      !formValues.fullname ||
      formValues.fullname.trim().length === 0 ||
      formValues.fullname.trim().split(" ").length < 2
    ) {
      input_validation.fullname = ["Please enter your full name"];
    }
    // street_address
    if (
      !formValues.street_address ||
      formValues.street_address.trim().length === 0
    ) {
      input_validation.street_address = ["Missing street address"];
      show_address_fields = true;
    }
    // suburb
    if (!formValues.suburb || formValues.suburb.trim().length === 0) {
      input_validation.suburb = ["Missing suburb"];
      show_address_fields = true;
    }
    // state
    if (
      !formValues.state ||
      formValues.state.trim().length === 0 ||
      formValues.state === "Enter State"
    ) {
      input_validation.state = ["Missing state"];
      show_address_fields = true;
    }
    // country
    if (!formValues.country || formValues.country.trim().length === 0) {
      input_validation.country = ["Missing country"];
      show_address_fields = true;
    }
    // postcode
    if (!formValues.postcode || formValues.postcode.trim().length === 0) {
      input_validation.postcode = ["Missing postcode"];
      show_address_fields = true;
    }
    console.log("input_validation", input_validation);

    if (Object.keys(input_validation).length > 0) {
      if (show_address_fields) {
        this.setState({ show_address_fields: true });
      }
      this.props.inputValidation(input_validation);
      if (this.addressInput) {
        this.addressInput.focus();
      }
    } else {
      console.log("VALID!!!");
      this.props.clearValidation();
      // setup data for ajax

      const names = formValues.fullname.trim().split(" ");
      const firstname = names[0];
      names.splice(0, 1);
      const lastname = names.join(" ");

      const region = this.state.regions.filter((v) => {
        return v.id == formValues.state;
      });
      console.log("region", region);

      let region_data = {};
      if (region.length > 0) {
        region_data = region[0];
      } else {
        console.log("Error getting region");
        return;
      }

      console.log("region_data", region_data);

      const new_shipping_address_data = {
        region: {
          region_code: region_data.code,
          region: region_data.name,
          region_id: region_data.id,
        },
        region_id: region_data.id,
        country_id: formValues.country,
        street: [formValues.street_address],
        telephone: formValues.mobile,
        postcode: formValues.postcode,
        city: formValues.suburb,
        firstname: firstname,
        lastname: lastname,
      };

      this.props.setLoader(true, "Updating Shipping...");
      const customer = this.props.cart.customer;
      customer.addresses.push(new_shipping_address_data);
      this.props.updateCustomer(
        {
          customer,
        },
        () => {
          this.setState({ savedShippingAddress: true });
          this.props.setLoader(false, null);
          history.push('/account/addressinformation')
        }
      );
    }
  };

  render() {
    let mobileMessage = this.props.input_validation.mobile.map((message) => (
      <div key={message} className="input-validation">
        {message}
      </div>
    ));
    let fullnameMessage = this.props.input_validation.fullname.map(
      (message) => (
        <div key={message} className="input-validation">
          {message}
        </div>
      )
    );
    let addressMessage = this.props.input_validation.address.map((message) => (
      <div key={message} className="input-validation">
        {message}
      </div>
    ));
    let streetAddressMessage = this.props.input_validation.street_address.map(
      (message) => (
        <div key={message} className="input-validation">
          {message}
        </div>
      )
    );
    let suburbMessage = this.props.input_validation.suburb.map((message) => (
      <div key={message} className="input-validation">
        {message}
      </div>
    ));
    let stateMessage = this.props.input_validation.state.map((message) => (
      <div key={message} className="input-validation">
        {message}
      </div>
    ));
    let countryMessage = this.props.input_validation.country.map((message) => (
      <div key={message} className="input-validation">
        {message}
      </div>
    ));
    let postcodeMessage = this.props.input_validation.postcode.map(
      (message) => (
        <div key={message} className="input-validation">
          {message}
        </div>
      )
    );

    return (
      <div id="page-account-add-address">
        <AccountNav />
        <div id="account-content">
          <form
            className="form"
            onSubmit={this.props.handleSubmit(this.handleSubmit)}
          >
            <div className="shipping-address-right add-shipping-address-container">
              <div id="checkout-shipping-form">
                <div id="add-new-address-heading">Add New Address</div>
                <div className="input-box">
                  <Field
                    name="fullname"
                    component={this.renderInput}
                    label="Fullname"
                    type="text"
                  />
                  {fullnameMessage}
                </div>
                <div className="input-box">
                  <Field
                    name="mobile"
                    component={this.renderInput}
                    label="Mobile"
                    type="text"
                    // readonly={true}
                  />
                  {mobileMessage}
                </div>
                <div className="input-box">
                  <Field
                    name="address"
                    component={this.renderInput}
                    label="Type Address..."
                    type="text"
                    id="google-places-input"
                    onKeyPress={(e) => {
                      console.log("in here7878");
                      if (e.key === "Enter") e.preventDefault();
                    }}
                    addressCallback={(input) => {
                      this.addressInput = input;
                    }}
                  />
                  {addressMessage}
                </div>
                {this.state.show_address_fields && (
                  <div>
                    <div className="input-box">
                      <Field
                        name="street_address"
                        component={this.renderInput}
                        label="Enter Street Address"
                        type="text"
                      />
                      {streetAddressMessage}
                    </div>
                    <div className="input-box">
                      <Field
                        name="suburb"
                        component={this.renderInput}
                        label="Enter Suburb"
                        type="text"
                      />
                      {suburbMessage}
                    </div>
                    <div className="input-box">
                      <Field
                        name="state"
                        component={this.renderSelect}
                        label="Enter State"
                        type="text"
                        isRegions={true}
                        value="569"
                      />
                      {stateMessage}
                    </div>
                    <div className="input-box">
                      <Field
                        name="country"
                        component={this.renderSelect}
                        label="Enter Country"
                        type="text"
                        isCountries={true}
                        defaultValue="AU"
                      />
                      {countryMessage}
                    </div>
                    <div className="input-box">
                      <Field
                        name="postcode"
                        component={this.renderInput}
                        label="Enter Postcode"
                        type="text"
                      />
                      {postcodeMessage}
                    </div>
                  </div>
                )}
                <button id="add-shipping-address-button" type="submit">
                  Save Address
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    input_validation: state.input_validation,
    cart: state.cart.cart
  };
};

const accountAddAddressPage = reduxForm({
  form: "add-address",
})(AccountAddAddressPage);

export default requireAuth(
  connect(mapStateToProps, { clearValidation, inputValidation, updateCustomer, fetchCart, setLoader })(accountAddAddressPage)
);
