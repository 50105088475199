import React from "react";
import { connect } from "react-redux";
import history from "../../history";
import { verifyToken, fetchCart, createEmptyCart, setSelectedShippingAddress, setCart, setLoader, logout, fetchProduct, setEmptyCart, fetchSeller } from "../../actions";

class AutologinPage extends React.Component {
  componentDidMount = () => {
    this.props.logout() 
    const jwt = this.props.match.params.jwt
    this.props.setLoader(true, "Verifying Token...")
    const autologin = typeof localStorage.autologin !== "undefined" ? localStorage.autologin : true
    delete localStorage.autologin 
    this.props.verifyToken(jwt, autologin, (data) => { 
      localStorage.campaign_id = data.campaign_id
      if(data.existing_user) {
        this.props.setLoader(true, "Creating Cart...")
        this.props.createEmptyCart(() => {
          this.props.setLoader(true, "Creating Cart...") 
          this.props.setEmptyCart(() => {
            this.props.setLoader(true, "Fetching Cart...")
            this.props.fetchCart(() => {  
              this.props.setSelectedShippingAddress(this.props.shipping_addresses.default_shipping, this.props.shipping_addresses.addresses)
              if(data.product) {
                this.props.setLoader(true, "Adding Product to Cart...")
                this.props.setCart(data.product, this.props.cart.id, () => {
                  this.props.setLoader(false, null)
                  history.push('/checkout')    
                })
              } else {
                history.push('/browse')    
              }
              
            })
          })
        })
      } else if(data.new_user) {
        this.props.setLoader(true, "Loading Registration...")
        localStorage.product_id = data.product_id 
        this.props.fetchProduct(data.product_id, () => {
          this.props.setLoader(false, null)
        })
        this.props.fetchSeller(data.product_id, () => {
          this.props.setLoader(false, null)
        })
        history.push('/register')
      } else if(data.expired_link) {
        localStorage.product_id = data.product_id 
        this.props.setLoader(false, null)
        history.push('/expiredlink')
      }
    })
  }

  render() {
    return (
      <div></div>
    );
  }
}

const mapStateToProps = state => {
  return {
    shipping_addresses: state.shipping_addresses,
    cart: state.cart.cart
  };
};

export default connect(
    mapStateToProps,
    { verifyToken, fetchCart, createEmptyCart, setSelectedShippingAddress, setCart, setLoader, logout, fetchProduct, setEmptyCart, fetchSeller }
  )(AutologinPage);
