import React from "react";
import { connect } from "react-redux";
import { fetchSeller, placeOrder, setLoader, fetchLocalStorageProducts, setLoaderCheckout, showSuppliedByFooter, hideSuppliedByFooter, hideSuppliedByPopup, hideSMPopup, setAffiliateSellerId } from "../../actions";
import CheckoutCart from "../CheckoutCart"
import CheckoutShipping from "../CheckoutShipping"
import CheckoutCards from "../CheckoutCards"
import history from "../../history";
import requireAuth from "../requireAuth";
import CheckoutGiftCards from "../CheckoutGiftCards";
const requiredFields = [
  "recipient_firstname",
  "recipient_lastname",
  "recipient_mobile",
  "recipient_message",
  "scheduled_delivery",
  "mms_template_id"
];
class CheckoutPage extends React.Component {
  constructor(props) {
    super(props)

    this.checkout_cards = React.createRef()

    const existingProductsJSON = localStorage.gift_card_details;
    const all_gift_cards = existingProductsJSON ? JSON.parse(existingProductsJSON) : {};
    this.state = { all_gift_cards: all_gift_cards, timeframe: 600, interval: null, disable_checkout: false };

  }

  componentDidMount() {
    this.props.showSuppliedByFooter()
    this.setClockInterval()
    this.validateGiftCards(this.state.all_gift_cards);
  }

  componentWillUnmount = () => {
    this.props.hideSuppliedByFooter()
    this.props.hideSuppliedByPopup()
    this.props.hideSMPopup()
    if(this.state.interval !== null) {
      clearInterval(this.state.interval)
    }
  }

  validateGiftCards =  (all_gift_cards) => {
    let validCards = [];
    let invalidCards = [];

    Object.keys(all_gift_cards).forEach((key) => {
      const card = all_gift_cards[key];
      let isValid = true;

      requiredFields.forEach((field) => {
        if (!card[field]) {
          isValid = false;
          return;
        }
      });

      if (isValid) {
        validCards.push({ key, card });
      } else {
        invalidCards.push({ key, card });
      }
    });

    const sortedCards = [...invalidCards, ...validCards];

    this.setState({
      disable_checkout: invalidCards.length > 0,
      all_gift_cards: sortedCards.reduce((result, { key, card }) => {
        result[key] = card;
        return result;
      }, {}),
    });
  }

  setClockInterval = () => {
    let interval = setInterval(() => {
      if(this.state.timeframe === 0) {
        clearInterval(interval);
        this.setState({ interval: null })
        history.push(`/sellerprofile/${this.props.seller.shop_url}`)
      } else {
        this.setState({ timeframe: --this.state.timeframe })
      }
    }, 1000)
    this.setState({ interval })
  }

  handleRemoveGiftCard = (key) => {
    const updatedGiftCards = {...this.state.all_gift_cards};
    delete updatedGiftCards[key];
    localStorage.setItem('gift_card_details', JSON.stringify(updatedGiftCards));
    this.validateGiftCards(updatedGiftCards);
    this.props.fetchLocalStorageProducts();
    if (Object.keys(updatedGiftCards).length === 0) {
      history.push(`/browse`);
    }
  }

  render = () => {

    // get seller image
    let seller_image = null;
    if(this.props.seller && this.props.cart_products.items.length > 0) {

      let seller_image_url = null

      // autlogin product
      if(this.props.autologin_product_id) {
        const temp_product = this.props.cart_products.items.find(v => {
          return v.id === this.props.autologin_product_id
        })
        if(temp_product && temp_product.product_seller_image !== null) {
          seller_image_url = temp_product.product_seller_image
        }
      }

      // first product
      if(seller_image_url === null) {
        const first_product = this.props.cart_products.items[0]
        if(first_product.product_seller_image !== null) {
          seller_image_url = first_product.product_seller_image
        }
      }

      // seller
      if(seller_image_url === null) {
        seller_image_url = this.props.seller.image
      }

      seller_image = <img src={seller_image_url} onClick={() => {
        history.push(`/sellerprofile/${this.props.seller.shop_url}`)
      }}/>
    }

    let minutes = Math.floor(this.state.timeframe / 60);
    let seconds = this.state.timeframe % 60;
    if (seconds < 10) {
        seconds = "0" + seconds;
    }


    return (
      <div id="page-checkout">
        {!this.state.disable_checkout && (
        <>
        <div id="checkout-top">
            <div id="checkout-header-img"></div>
            <div id="checkout-header-clock">
            <div id="checkout-header-confirm">Confirm in</div>
            <span id="clock-minutes">{minutes}</span>:
            <span id="clock-seconds">{seconds}</span>
            </div>
        </div>
        </>
        )}
        <CheckoutCart all_gift_cards={this.state.all_gift_cards} disable_checkout={this.state.disable_checkout} />
        <div className="clear"></div>
        <CheckoutGiftCards all_gift_cards={this.state.all_gift_cards} disable_checkout={this.state.disable_checkout} onRemoveGiftCard={this.handleRemoveGiftCard} />
        <div className="clear"></div>
        {this.state.disable_checkout ? (
          <div id="checkout-confirm-purchase">
            <button
              type="submit"
              disabled={true}
            >
              Confirm Purchase
            </button>
            <img
              id="checkout-lock-logo"
              src="https://cdn-staging.securemobile.net/web/images/SecureMobile-lock-logo.png"
            />
          </div>
        ) : (
          <CheckoutCards ref={this.checkout_cards} disable_checkout={this.state.disable_checkout} handleCancelTimer={() => {
            clearInterval(this.state.interval)
          }} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    seller: state.seller.seller,
    payment_card: state.payment_card,
    billing_address: state.billing_address.billing_address,
    cart: state.cart.cart,
    autologin_product_id: state.autologin_product,
    cart_products: state.cart_products.cart_products,
    auth: state.auth
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchSeller, placeOrder, setLoader, fetchLocalStorageProducts, setLoaderCheckout, showSuppliedByFooter, hideSuppliedByFooter, hideSuppliedByPopup, hideSMPopup, setAffiliateSellerId }
  )(CheckoutPage)
);
