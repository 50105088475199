import _ from "lodash";
import {
  UPDATE_LOCALSTORAGE,
  FETCH_LOCALSTORAGE_PRODUCTS
} from "../actions/types";

const INITIAL_STATE = {
  localstorage_products: {}
};

export default (state = INITIAL_STATE, action) => {
  let localstorage_products, new_state;
  switch (action.type) {
    case UPDATE_LOCALSTORAGE:
      return { ...state, localstorage_products: action.payload };
    case FETCH_LOCALSTORAGE_PRODUCTS:
      localstorage_products = {
        ...action.payload,
      };
      console.log('localstorage_products',localstorage_products)
      new_state = {
        localstorage_products: localstorage_products,
      };
      console.log('new_state',new_state)
      return new_state;
    default:
      return state;
  }
};
