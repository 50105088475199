import React from "react";
import { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime, fetchProduct, fetchGiftCardDetailsById } from "../../actions"
import { connect } from "react-redux";
import requireAuth from "../requireAuth"; 
import history from "../../history";
import CONSTANTS from "../../constants"
import IPhone from "../IPhone";

class GiftCardSendNowOrSchedulePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      product_uuid: null,
      product_id: null,
      details: {}
    }
  }

  componentDidMount = () => {
    const product_uuid = this.props.match.params.productuuid;
    const existingProductsJSON = localStorage.gift_card_details;
    const existingProducts = existingProductsJSON ? JSON.parse(existingProductsJSON) : {};
    const existingProduct = existingProducts[product_uuid]
    const product_id = existingProduct.id

    this.setState({ product_id: product_id,  product_uuid: product_uuid, details: existingProduct})

  }

  render() {
    return (
      <div id="page-gift-card-send-now-or-schedule">
        <h1 className="h1">Gift Card Preview</h1>
        <div>
          <div className="product-gift-card-details-container">
            <div id="phone-message-campaign">
              <div style={{margin: '0 auto', width: '60%'}}>
                <div style={{ height: "calc(100% - 45px)" }}>
                  <div className="flex justify-between items-center h-12">
                    <div>
                      <i
                        className="fas fa-chevron-left text-alt-lime relative"
                        style={{ left: 15 }}
                      ></i>
                    </div>
                    <div>
                      <i
                        className="fas fa-user-circle text-2xl text-gray-400 relative"
                        style={{ left: -9 }}
                      ></i>
                    </div>
                    <div></div>
                  </div>
                  <div
                    className="overflow-y-auto mb-2"
                    style={{ height: "calc(100% - 60px)" }}
                  >
                    <div>
                      <div className="px-3" style={{ padding: '0 0.75rem' }}>
                        <div className="mr-10 mb-2">
                          <img src={this.state.details.mms_template_url} style={{width: '100%'}} />
                        </div>
                        <div className="inline-block">
                          <div className="bg-received-message-grey rounded-lg mr-10 text-sm px-2 py-1 break-all whitespace-pre-wrap" style={{backgroundColor: '#cccccc', padding: '0.25rem 0.5rem', borderRadius: '0.5rem', fontSize:'13px'}}>
                            <p>Hi {this.state.details.recipient_firstname},</p>
                            <p>
                              {this.state.details.text_content
                                ? this.state.details.text_content.replace('<giftcard>', 'Add2Wallet.link/xxxxxxx')
                                : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="gift-card-show-details">
              <div className="show-details-row">
                <div className="show-details-desc">Deliver To:</div>
                <div className="show-details-value" style={{ width: '70%' }}>{this.state.details.recipient_firstname} {this.state.details.recipient_lastname}</div>
              </div>
              <div className="show-details-row" style={{ marginBottom: 20 }}>
                <div className="show-details-desc">On Mobile:</div>
                <div className="show-details-value" style={{ width: '70%' }}>{this.state.details.recipient_mobile}</div>
              </div>
              <div id="show-details-action-buttons" style={{textAlign: 'center'}}>
                <button className="show-details-button" style={{borderRadius: '30px', fontSize: '16px', fontWeight: '700', width: '170px'}} onClick={() => {
                  history.push(`/checkout`)
                }}>Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    gift_card_details: state.gift_card_details.gift_card_details,
    product: state.product.product 
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime, fetchProduct, fetchGiftCardDetailsById }
  )(GiftCardSendNowOrSchedulePage) 
);
