import React from "react";
import { connect } from "react-redux";
import { getCDN } from "../../helper/getCDN";
import { enteredHomePage, leavingHomePage } from "../../actions";
import HubspotForm from "react-hubspot-form";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    this.props.enteredHomePage();

    this.renderCaptchaJS();
  };

  renderCaptchaJS = () => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = 'function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value =JSON.stringify(elems);}}setInterval(timestamp, 500);';
    this.instance.appendChild(s);
  };

  componentWillUnmount = () => {
    this.props.leavingHomePage();
  };

  executeScroll = () =>
    this.myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  render() {
    const html =
      '<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script><script data-hubspot-rendered="true">hbspt.forms.create({portalId: "8085500",formId: "7f22db3f-1666-4bb3-8e94-23a091072d62"});</script>';

    return (
      <div id="page-home">
        <div id="home-page-container">
          {/* <div id="home-main-section">
            <div id="home-black-overlay"></div>
            <video
              id="home-video"
              autoplay="true"
              muted="true"
              playsinline="truew"
              loop="true"
              src={`${getCDN()}web/videos/SecureMobileWebsite.mp4`}
            ></video>
            <div id="home-content">
              <div id="home-top-content">
                <img
                  id="home-top-content-image"
                  src={`${getCDN()}web/images/rapid-shop-transparent-2.png`}
                />
              </div>
              <div id="home-middle-content">
                <div id="home-middle-content-text">Get it on mobile</div>
              </div>
              <div id="home-bottom-content">
                <div id="home-bottom-content-text">
                  Want to register your interest?
                </div>
                <button
                  id="home-bottom-content-button"
                  onClick={() => {
                    this.executeScroll();
                  }}
                >
                  Register
                </button>
              </div>
            </div>
          </div> */}
          <div id="home-main-section">
            <div id="home-banner-content">
              <div id="home-banner-content-container">
                <div>
                  <div id="home-banner-left">
                    <div id="home-banner-left-content">
                      <div id="home-banner-heading-1">Tap2Buy Coming Soon</div>
                      <div id="home-banner-heading-2">
                        Enable One Tap Commerce
                      </div>
                      <div id="home-banner-text">
                        Removal of friction from the online purchase and
                        checkout process improves conversion and customer
                        satisfaction. Tap2Buy is the first text based online
                        commerce platform, using the phone as security, and
                        removing the need for passwords, usernames, email and
                        logons.
                      </div>
                      <div id="home-banner-button-parent">
                        <button
                          id="home-banner-button"
                          onClick={() => {
                            this.executeScroll();
                          }}
                        >
                          Request a demo
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="home-banner-right">
                    <img src={"/tap2buy-image.png"} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div id="home-form-section" ref={this.myRef}>
            <div className="container">
              <div id="home-form-content">
                <div id="form-title">Register your interest</div>
                <div id="form-form">
                  <HubspotForm
                    portalId="8085500"
                    formId="c7d1365b-0539-4ee7-b41c-17ae8e3fab6e"
                    onSubmit={() => console.log("Submit!")}
                    onReady={(form) => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div id="home-form-section" ref={this.myRef}>
            <div className="container">
              <div id="home-form-content">
                <div id="form-title">Request a Demo</div>
                <div id="form-request-demo">
                  {/* <!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.             -->
<!--  ----------------------------------------------------------------------  --> */}

                  <form
                    action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                    method="POST"
                  >
                    <input
                      type="hidden"
                      name="captcha_settings"
                      value='{"keyname":"recaptcha","fallback":"true","orgId":"00D5i000001tlvP","ts":""}'
                    />
                    <input type="hidden" name="oid" value="00D5i000001tlvP" />
                    <input
                      type="hidden"
                      name="retURL"
                      value="https://intouch.digital"
                    />

                    {/* <!--  ----------------------------------------------------------------------  -->
<!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
<!--  these lines if you wish to test in debug mode.                          -->
<!--  <input type="hidden" name="debug" value="1">                            -->
<!--  <input type="hidden" name="debugEmail"                                  -->
<!--  value="eibhlis@mobile.digital">                                         -->
<!--  ----------------------------------------------------------------------  --> */}

                    <input
                      className="text-field"
                      id="first_name"
                      maxLength="40"
                      name="first_name"
                      size="20"
                      type="text"
                      placeholder="Firstname"
                    />
                    <br />

                    <input
                      className="text-field"
                      id="last_name"
                      maxLength="80"
                      name="last_name"
                      size="20"
                      type="text"
                      placeholder="Surname"
                    />
                    <br />

                    <input
                      className="text-field"
                      id="company"
                      maxLength="40"
                      name="company"
                      size="20"
                      type="text"
                      placeholder="Company Name"
                    />
                    <br />

                    <input
                      className="text-field"
                      id="email"
                      maxLength="80"
                      name="email"
                      size="20"
                      type="text"
                      placeholder="Email"
                    />
                    <br />

                    <input
                      className="text-field"
                      id="phone"
                      maxLength="40"
                      name="phone"
                      size="20"
                      type="text"
                      placeholder="Mobile"
                    />
                    <br />

                    <input
                      className="text-field"
                      id="country"
                      maxLength="40"
                      name="country"
                      size="20"
                      type="text"
                      placeholder="Country"
                    />
                    <br />

                    <input
                      id="00N5i00000EC5Bb"
                      name="00N5i00000EC5Bb"
                      type="checkbox"
                      value="1"
                    />
                    <label className="sf-form-optin-text" htmlFor="00N5i00000EC5Bb">
                      I agree to receive communications from intouch.
                    </label>
                    <p>
                      By creating this request, we'll send you information
                      around our products, services and special offers. You can
                      opt-out at any time, view our{" "}
                      <a href="https://intouch.digal/privacy-policy">
                        privacy policy
                      </a>
                    </p>
                    <div
                      className="g-recaptcha"
                      data-sitekey="6LeQ5R4UAAAAANEhkPrEY5zvbEAd8Rq7UIDOlc-h"
                    ></div>
                    <br />
                    <input
                      type="submit"
                      name="submit"
                      id="form-request-demo-button"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={el => (this.instance = el)} />
      </div>
    );
  }
}

export default connect(null, { enteredHomePage, leavingHomePage })(HomePage);
