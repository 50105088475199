import React from "react";
import { connect } from "react-redux";
import requireAuth from "../requireAuth";
import { fetchLocalStorageProducts } from "../../actions";

class SuccessPage extends React.Component {
  render() { 
    let shop_name = null, order_id = null
    delete localStorage.gift_card_details
    this.props.fetchLocalStorageProducts()
    if(this.props.checkout_success) {
      shop_name = this.props.checkout_success.shop_name
      order_id = this.props.checkout_success.order_id
      return (
        <div id="page-success">
          <div id="success-image-parent">
            <img id="success-image" src="https://cdn.tap2buy.com/web/images/thank-you-green-tick.png"/>
            <div id="thank-you-heading">Thank You</div>
          </div>
          <div id="thank-you-order">
            <div id="thank-you-order-merchant-message">Your order has been placed with:</div>
            <div id="thank-you-order-merchant">{shop_name}</div>
            <div id="thank-you-order-message">Your order number is:</div>
            <div id="thank-you-order-number">{order_id}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="page-success">
          <div id="success-image-parent">
            <img id="success-image" src="https://cdn.tap2buy.com/web/images/thank-you-green-tick.png"/>
            <div id="thank-you-heading">Thank You</div>
          </div>
          <div id="thank-you-order">
            <div id="thank-you-order-merchant-message">Your order has been placed</div>
          </div>
        </div>
      );
    }


  }
}

const mapStateToProps = state => {
  return {
    checkout_success: state.checkout_success.checkout_success
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchLocalStorageProducts }
  )(SuccessPage)
);
