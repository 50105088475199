import React from "react";
import { connect } from "react-redux";
import {
  fetchGiftCardDetailsByCart,
  removeGiftCardDetailsByCartItemId,
  removeProductFromCart,
  setLoader,
  setLoaderCheckout,
  fetchCartProducts,
} from "../actions";
import history from "../history";
import { store } from "../store";
import {convertPrice} from "../helper/common";

class CheckoutGiftCards extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      showGiftCards: false,
      all_gift_cards: []
    };
  }

  componentDidMount = () => {
    //if(this.props.disable_checkout)
      //this.myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  renderGiftCards = () => {
    const country_code = localStorage.getItem('country_code') || 'AU';
    const currency_rate = localStorage.getItem('currency_rate')
      ? JSON.parse(localStorage.getItem('currency_rate'))
      : {};
    const currencySymbol = country_code === 'AU' ? 'AUD' : 'USD';
    if (Object.keys(this.props.all_gift_cards).length > 0) {
      const keys = Object.keys(this.props.all_gift_cards)
      return keys.map((key) => {
        const v = this.props.all_gift_cards[key]
        let formattedDatetime = 'Not Set';
        if(v.scheduled_delivery) {
          const [date, time] = v.scheduled_delivery.split(" ");
          formattedDatetime = `${date} ${time.substr(0, 5)}`;
        }

        return (
          <div className="product-gift-card-details-container" key={key}>
            <div className="gift-card-details-product-info">
              <div className="gift-card-details-row">
                <div className="gift-card-details-label">
                  {v.name} ${convertPrice(v.price, country_code, currency_rate)} {currencySymbol}
                </div>
              </div>
              <div className="gift-card-details-row">
                <div className="gift-card-details-label">To:</div>
                <div className="gift-card-details-info">
                  {v.recipient_firstname} {v.recipient_lastname}
                </div>
                {(!v.recipient_firstname || !v.recipient_lastname) && (
                  <div className="card-validation pb-1">
                    Please enter recipient's name
                  </div>
                )}
              </div>
              <div className="gift-card-details-row">
                <div className="gift-card-details-label">Mobile:</div>
                <div className="gift-card-details-info">
                  {v.recipient_mobile}
                </div>
                {!v.recipient_mobile && (
                  <div className="card-validation pb-1">
                    Please enter recipient's mobile
                  </div>
                )}
              </div>
              <div className="gift-card-details-row">
                <div className="gift-card-details-label">Image:</div>
                <div className="gift-card-details-info">
                  <img
                    src={v.mms_template_url ? v.mms_template_url : 'https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/Magento_Catalog/images/product/placeholder/thumbnail.jpg'}
                    style={{
                      width: "150px",
                      border: "1px solid #c2dedb",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  />
                </div>
                {!v.mms_template_url && (
                  <div className="card-validation pb-1">
                    Please select a template
                  </div>
                )}
              </div>
              <div className="gift-card-details-row">
                <div className="gift-card-details-label">Message:</div>
                <div className="gift-card-details-info">
                  {v.recipient_message}
                </div>
                {!v.recipient_message && (
                  <div className="card-validation pb-1">
                    Please enter recipient's message
                  </div>
                )}
              </div>
              <div className="gift-card-details-row">
                <div className="gift-card-details-label">Send Time:</div>
                <div className="gift-card-details-info">{formattedDatetime}</div>
                {!v.scheduled_delivery && (
                  <div className="card-validation pb-1">
                    Please select scheduled delivery date
                  </div>
                )}
              </div>
              <div style={{
                float: 'left',
                position: 'absolute',
                top: '5px',
                right: '9px'
              }}>
                <button
                  style={{
                    background: "#bfdd00",
                    color: "#022a1e",
                    fontSize: "16px",
                    textAlign: "center",
                    padding: "10px 30px",
                    borderRadius: "25px",
                    marginTop: "5px",
                    fontWeight: "400",
                  }}
                  onClick={() => history.push(`/giftcard/add/${key}`)}
                  // onClick={() => history.push(`/giftcard/edit/${v.id}`)}
                >
                  Change
                </button>
              </div>
              <div style={{
                float: 'left',
                position: 'absolute',
                top: '55px',
                right: '9px'
              }}>
                <button
                  style={{
                    background: "#ff623e",
                    color: "#ffffff",
                    fontSize: "16px",
                    textAlign: "center",
                    padding: "10px 30px",
                    borderRadius: "25px",
                    marginTop: "5px",
                    fontWeight: "400",
                  }}
                  onClick={() => this.props.onRemoveGiftCard(key)}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  render() {
    return Object.keys(this.props.all_gift_cards).length ? (
      <div id="checkout-gift-cards" ref={this.myRef}>
        <div id="checkout-gift-cards-heading">
          {(this.state.showGiftCards || this.props.disable_checkout) ? "Hide" : "View"} Gift Card
        </div>
        <div
          className="checkout-arrow"
          onClick={() => {
            this.setState({ showGiftCards: !this.state.showGiftCards });
          }}
        ></div>
        {this.props.disable_checkout && (
          <div style={{ marginTop: "10px"}}>
            Missing required information. Please hit ‘Change’ to complete, or ‘Remove’ to remove from cart.
          </div>
        )}
        {(this.state.showGiftCards || this.props.disable_checkout) && (
          <div style={{ marginTop: "10px" }}>{this.renderGiftCards()}</div>
        )}
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    cart_products: state.cart_products.cart_products,
    gift_card_details: state.gift_card_details.gift_card_details,
    cart: state.cart.cart,
  };
};

export default connect(mapStateToProps, {
  fetchGiftCardDetailsByCart,
  removeGiftCardDetailsByCartItemId,
  removeProductFromCart,
  setLoader,
  setLoaderCheckout,
  fetchCartProducts,
})(CheckoutGiftCards);
