import React from "react";
import {
  createEmptyCart,
  setEmptyCart,
  setCart,
  fetchCart,
  fetchSavedCards,
  setPaymentMethod,
  saveGiftCardDetails,
  fetchMMSTemplates,
  saveGiftCardSendTime,
  saveGiftCardMMSTemplate,
  placeOrder,
  inputValidation,
  clearValidation,
  setShippingInformation,
  setLoaderCheckout,
  setLoader,
  setSelectedShippingAddress,
  getCheckoutSuccess,
  saveGiftCardOrder,
  clearGiftCardDetails
} from "../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import history from "../history";
import { getReCaptchaToken } from "../helper/recaptcha";

class CheckoutCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddNewCard: false,
      all_gift_cards: [],
      cartInfo: {},
    };
  }

  componentDidMount = () => {
    const existingProductsJSON = localStorage.gift_card_details;
    const all_gift_cards = existingProductsJSON ? JSON.parse(existingProductsJSON) : {};
    if (Object.keys(all_gift_cards).length === 0) {
      this.props.setLoader(false, null);
      history.push("/browse");
    }
    this.setState({all_gift_cards: all_gift_cards});
    if (this.props.auth.is_signed_in) {
      this.props.fetchSavedCards(() => {
        this.props.setLoaderCheckout("saved_cards");
        // if(this.props.saved_cards.length ===0)
        //   this.setState({ showAddNewCard: true });
      });
    }
  };

  handleSubmit = async (event) => {
    let input_validation = {};

    let pm = this.props.payment_card.pm;
    if (pm === "new_card") {
      const { stripe, elements } = this.props;
      this.props.setLoader(true, "Getting Card Information...");
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      console.log("error", error);
      console.log("paymentMethod", paymentMethod);
      this.props.clearValidation();
      if (error) {
        console.log("error", error);
        input_validation.card = [error.message];
      } else {
        pm = paymentMethod.id;
      }
    }

    if (pm === null) {
      input_validation.card = ["Please enter a Card for Payment"];
    }

    //if (!this.props.selected_shipping_address) {
      //input_validation.address = ["Please complete your shipping address"];
    //}

    if (Object.keys(input_validation).length > 0) {
      this.props.inputValidation(input_validation);
      return;
    }

    this.props.handleCancelTimer();

    const has_gift_card = true;

    this.props.createEmptyCart(() => {
      this.props.setLoader(true, "Creating Cart...")
      this.props.setEmptyCart(() => {
        this.props.setLoader(true, "Fetching Cart...")
        this.props.fetchCart(() => {
          this.props.setSelectedShippingAddress(
            this.props.shipping_addresses.default_shipping,
            this.props.shipping_addresses.addresses
          );
          this.props.setLoader(true, "Adding Product to Cart...")
          if (Object.keys(this.state.all_gift_cards).length > 0) {
            const keys = Object.keys(this.state.all_gift_cards)
            keys.forEach((key, index) => {
              const v = this.state.all_gift_cards[key]
              const purchaserMobile = localStorage.mobile;
              const configurable_item_options = v.product_option.extension_attributes.configurable_item_options.map(option => {
                return {
                  option_id: option.option_id,
                  option_value: option.option_value
                }
              })
              const product_data = {
                product_type: "configurable",
                sku: v.sku,
                qty: 1,
                product_option: {
                  extension_attributes: {
                    configurable_item_options: configurable_item_options
                  }
                }
              }
              this.props.setCart(product_data, this.props.cart.id, ({ item_id }) => {
                const giftcarddetail = {
                  recipient_firstname : v.recipient_firstname,
                  recipient_lastname : v.recipient_lastname,
                  recipient_mobile : v.recipient_mobile,
                  recipient_message : v.recipient_message,
                  text_content : v.text_content,
                  configurable_product_sku : v.sku,
                  purchaser_mobile : purchaserMobile,
                  gift_card_price : v.price,
                  product_id : v.id,
                  cart_id : this.props.cart.id,
                  cart_item_id : item_id
                }

                this.props.saveGiftCardDetails(giftcarddetail,(gift_card_details_id) =>{
                  this.props.setLoader(true, "Saving Product to Order...")
                  this.props.saveGiftCardSendTime(gift_card_details_id, v.scheduled_delivery, v.timezone)
                  this.props.saveGiftCardMMSTemplate(gift_card_details_id, v.mms_template_id, v.click2_giftcard_mms_template_id)
                  // If this is the last gift card, create order
                  if (index === keys.length - 1) {
                    const data = {
                      billingAddress: {
                        city: this.props.cart.billing_address.city,
                        countryId: this.props.cart.billing_address.country_id,
                        customerAddressId: this.props.cart.billing_address.customer_address_id,
                        customerId: this.props.cart.billing_address.customer_id,
                        email: this.props.cart.billing_address.email,
                        firstname: this.props.cart.billing_address.firstname,
                        lastname: this.props.cart.billing_address.lastname,
                        postcode: this.props.cart.billing_address.postcode,
                        region: this.props.cart.billing_address.region,
                        regionCode: this.props.cart.billing_address.region_code,
                        regionId: this.props.cart.billing_address.region_id,
                        saveInAddressBook: this.props.cart.billing_address.save_in_address_book,
                        street: this.props.cart.billing_address.street,
                        telephone: this.props.cart.billing_address.telephone,
                      },
                      paymentMethod: {
                        method: "stripe_payments",
                        additional_data: {
                          cc_save: true,
                          cc_stripejs_token: pm,
                        },
                      },
                    };
                    console.log('placing order');
                    this.setState({'cartInfo': this.props.cart})
                    this.props.placeOrder(data, () => {
                      this.props.setLoader(true, "Place Order...")
                      this.props.getCheckoutSuccess(this.props.affiliate_seller_id, this.props.seller, localStorage.campaign_id, (order_id) => {
                        delete localStorage.campaign_id
                        getReCaptchaToken('checkout', (token) => {
                          const data = {
                            r_token: token,
                            cart_id: this.state.cartInfo.id,
                            order_id: order_id,
                          }
                          this.props.saveGiftCardOrder(data, () => {
                            this.props.clearGiftCardDetails()
                            this.props.setLoader(false, null)
                          })
                        })
                      })
                      history.push('/success')
                    })
                  }
                })
              })
            })
          }
        })
      })
    })
  };

  renderSavedCards = () => {
    let check_first = this.state.showAddNewCard ? false : true;
    const card_rows = this.props.saved_cards.map((v, i, a) => {
      let month = v.exp_month < 10 ? "0" + v.exp_month : v.exp_month;
      let year = v.exp_year.toString().substring(2, 4);
      let expiry = month + "/" + year;

      let checked = false;
      if (check_first && i === 0) {
        checked = true;
      }

      return (
        <div key={v.id} className="checkout-card">
          <div className="card-radio-parent">
            <input
              type="radio"
              name="card"
              defaultChecked={checked}
              className="card-radio"
              onClick={() => {
                this.props.setPaymentMethod(v.id);
              }}
            />
          </div>
          <div className="card-number-parent">
            <div className="card-number">{v.last4}</div>
          </div>
          <div className="card-expiry-parent">
            <div className="card-expiry">exp {expiry}</div>
          </div>
        </div>
      );
    });

    if (this.state.showAddNewCard) {
      card_rows[card_rows.length] = (
        <div key="new_card" className="checkout-card">
          <div className="card-radio-parent">
            <input
              type="radio"
              name="card"
              defaultChecked={true}
              id="card-radio-new"
              onClick={() => {
                this.props.setPaymentMethod("new_card");
              }}
            />
          </div>
          <div id="card-new-card-heading">
            <div id="add-new-card-heading">Add New Card</div>
          </div>
        </div>
      );
    }

    return card_rows;
  };

  render() {
    let cardMessage = this.props.input_validation.card.map((message) => (
      <div key={message} className="input-validation">
        {message}
      </div>
    ));
    return (
      <React.Fragment>
        <div id="checkout-payment">
          <div id="checkout-payment-heading">Payment Information</div>
          <div id="checkout-cards">{this.renderSavedCards()}</div>
          {this.state.showAddNewCard && (
            <div id="card-new-card-inputs" className="form">
              <div className="input-box">
                <CardElement
                  // ref={(instance) => { instance._element.focus(); }}
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                      },
                    },
                  }}
                />
              </div>
              <div style={{
                'textAlign': 'center',
                'fontSize': '12px'
              }} id="agreement-text">Your card information will be encrypted securely to enable 1-click checkout.</div>
            </div>
          )}
          {!this.state.showAddNewCard && (
            <div
              className="payment-add-update"
              onClick={() => {
                this.setState({ showAddNewCard: true });
                this.props.setPaymentMethod("new_card");
              }}
            >
              Add New
            </div>
          )}
          <div id="payment-failed-message">{cardMessage}</div>
          <div
            className="checkout-arrow"
            onClick={() => {
              if (!this.state.showAddNewCard) {
                this.props.setPaymentMethod("new_card");
              } else {
                if (this.props.saved_cards.length > 0) {
                  this.props.setPaymentMethod(this.props.saved_cards[0].id);
                } else {
                  this.props.setPaymentMethod(null);
                }
              }

              this.setState({ showAddNewCard: !this.state.showAddNewCard });
            }}
          ></div>
        </div>
        <div id="checkout-confirm-purchase">
          <button
            type="submit"
            disabled={this.props.disable_checkout_button}
            onClick={(e) => {
              this.handleSubmit(e);
            }}
          >
            Confirm Purchase
          </button>
          <img
            id="checkout-lock-logo"
            src="https://cdn-staging.securemobile.net/web/images/SecureMobile-lock-logo.png"
          />
        </div>
        {/* {(has_gift_card && !this.props.gift_card_details_reviewed) ?
          <div id="checkout-review-gift-card-details">
            <button onClick={() => {
              history.push('/giftcarddetails')
            }}>Review Gift Card Details</button>
          </div>
          : <div id="checkout-confirm-purchase">
              <button type="submit" disabled={this.props.disable_checkout_button} onClick={(e) => {
                this.handleSubmit(e)
              }}>Confirm Purchase</button>
            </div>
        } */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    saved_cards: state.saved_cards.saved_cards,
    input_validation: state.input_validation,
    billing_address: state.billing_address.billing_address,
    cart: state.cart.cart,
    payment_card: state.payment_card,
    shipping_addresses: state.shipping_addresses,
    shipping_method: state.shipping_method.shipping_method,
    selected_shipping_address:
      state.selected_shipping_address.selected_shipping_address,
    seller: state.seller.seller,
    auth: state.auth,
    disable_checkout_button: state.disable_checkout_button,
    affiliate_seller_id: state.affiliate_seller_id,
    cart_products: state.cart_products.cart_products,
    gift_card_details: state.gift_card_details.gift_card_details,
  };
};

const ReadyCheckoutCards = connect(mapStateToProps, {
  createEmptyCart,
  setEmptyCart,
  setCart,
  fetchCart,
  fetchSavedCards,
  saveGiftCardDetails,
  fetchMMSTemplates,
  saveGiftCardSendTime,
  saveGiftCardMMSTemplate,
  setPaymentMethod,
  placeOrder,
  inputValidation,
  clearValidation,
  setShippingInformation,
  setLoaderCheckout,
  setLoader,
  setSelectedShippingAddress,
  getCheckoutSuccess,
  saveGiftCardOrder,
  clearGiftCardDetails
})(CheckoutCards);

const InjectedCheckoutCards = ({ handleCancelTimer }) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <ReadyCheckoutCards
        stripe={stripe}
        elements={elements}
        handleCancelTimer={handleCancelTimer}
      />
    )}
  </ElementsConsumer>
);

let pk_stripe = null;
if (
  window.location.origin === "https://www.tap2buy.com" ||
  window.location.origin ===
    "https://live-giftguru.dnrx1u9c16dvg.amplifyapp.com"
) {
  pk_stripe = "pk_live_RQdsNdxOG0IYGefPnPF14tFY"; //"pk_live_RQdsNdxOG0IYGefPnPF14tFY"
} else {
  pk_stripe = "pk_test_rnMv6e5UnHRP3LkC8dW6TyZr00hBMZIlvn";
}

const stripePromise = loadStripe(pk_stripe);

const ReadyRegistration = ({ handleCancelTimer }) => (
  <Elements stripe={stripePromise}>
    <InjectedCheckoutCards handleCancelTimer={handleCancelTimer} />
  </Elements>
);

export default ReadyRegistration;
