import React from "react";
import { fetchCart, createEmptyCart, fetchCartProducts, fetchProduct, saveGiftCardDetails, fetchGiftCardDetailsById, editGiftCardDetails } from "../../actions";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import requireAuth from "../requireAuth"; 
import history from "../../history";
import CONSTANTS from "../../constants"
import GiftCardForm from "../GiftCardForm";

class GiftCardEditPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gift_card_details_id: null,
      gift_card_details: null, 
    }
  }

  componentDidMount = () => {
    const gift_card_details_id = this.props.match.params.giftcarddetailsid

    this.setState({ gift_card_details_id: gift_card_details_id })

    if(this.props.auth.is_signed_in) {
      this.props.createEmptyCart(() => {
        this.props.fetchCart()
        this.props.fetchCartProducts(() => {
        })
      })
    } 
    this.props.fetchGiftCardDetailsById( gift_card_details_id, (gift_card_details) => { 
      console.log('gift_card_details', gift_card_details)
      this.setState({ gift_card_details: gift_card_details })
    })
  }

  render() {
    return ( 
      <div id="page-gift-card-recipient-details"> 
        <h1 className="h1" style={{ marginBottom: 19 }}>Gift Card Recipient Details</h1>
        {this.state.gift_card_details && <GiftCardForm 
          gift_card_details={this.state.gift_card_details}
          configurable_product_sku={this.state.gift_card_details.configurable_product_sku} 
          product_id={this.state.gift_card_details.product_id}
          handleAction={(data) => {
            let gift_card_details = {
              ...data
            }

            this.props.editGiftCardDetails(this.state.gift_card_details_id, gift_card_details, () => {
              //history.push(`/giftcard/sendnoworschedule/${this.state.gift_card_details_id}`)
              history.push(`/giftcard/mmstemplates/${this.state.gift_card_details_id}`)
            })     
          }}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    gift_card_details: state.gift_card_details.gift_card_details,
    product: state.product.product
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchCart, createEmptyCart, fetchCartProducts, fetchProduct, saveGiftCardDetails, fetchGiftCardDetailsById, editGiftCardDetails }
  )(GiftCardEditPage) 
);
