export const SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA";
export const AUTOLOGIN_ERROR = "AUTOLOGIN_ERROR";
export const FETCH_BROWSE_PRODUCTS = "FETCH_BROWSE_PRODUCTS";
export const FETCH_CATEGORY_PRODUCTS = "FETCH_CATEGORY_PRODUCTS";
export const FETCH_SELLER_PRODUCTS = "FETCH_SELLER_PRODUCTS";
export const FETCH_SELLER = "FETCH_SELLER";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const CLEAR_VALIDATION = "CLEAR_VALIDATION";
export const REGISTER = "REGISTER";
export const INPUT_VALIDATION = "INPUT_VALIDATION";
export const SHOW_CART = "SHOW_CART";
export const SHOW_NAV_MENU = "SHOW_NAV_MENU";
export const FETCH_CART = "FETCH_CART";
export const FETCH_SHIPPING_METHOD = "FETCH_SHIPPING_METHOD";
export const FETCH_SHIPPING_INFORMATION = "FETCH_SHIPPING_INFORMATION";
export const FETCH_SAVED_CARDS = "FETCH_SAVED_CARDS";
export const HIDE_CART = "HIDE_CART";
export const HIDE_NAV_MENU = "HIDE_NAV_MENU";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const SET_AUTHORIZATION_TOKEN = "SET_AUTHORIZATION_TOKEN";
export const SET_JWT = "SET_JWT"; 
export const SET_CHECKOUT_SUCCESS = "SET_CHECKOUT_SUCCESS"; 
export const FETCH_CART_PRODUCTS = "FETCH_CART_PRODUCTS";
export const FETCH_SHIPPING_ADDRESSES = "FETCH_SHIPPING_ADDRESSES"; 
export const SET_PAYMENT_CARD = "SET_PAYMENT_CARD";
export const FETCH_BILLING_ADDRESS = "FETCH_BILLING_ADDRESS";
export const FETCH_SHIPPING_METHOD_CUSTOM = "FETCH_SHIPPING_METHOD_CUSTOM";
export const SET_SELECTED_SHIPPING_ADDRESS = "SET_SELECTED_SHIPPING_ADDRESS";
export const SET_LOADER = "SET_LOADER";
export const SET_LOADER_CHECKOUT = "SET_LOADER_CHECKOUT";
export const SET_AUTOLOGIN_PRODUCT = "SET_AUTOLOGIN_PRODUCT";
export const SHOW_MOBILE_POPUP = "SHOW_MOBILE_POPUP";
export const HIDE_MOBILE_POPUP = "HIDE_MOBILE_POPUP";
export const SET_UPDATE_PRODUCT_DETAILS = "SET_UPDATE_PRODUCT_DETAILS";
export const FETCH_UPDATE_PRODUCT_DETAILS = "FETCH_UPDATE_PRODUCT_DETAILS"; 
export const UNSET_AUTHORIZATION_TOKEN = "UNSET_AUTHORIZATION_TOKEN"; 
export const ADD_NEW_SHIPPING_ADDRESS = "ADD_NEW_SHIPPING_ADDRESS"; 
export const SET_OFFLINE = "SET_OFFLINE"; 
export const SHOW_SM_POPUP = "SHOW_SM_POPUP"; 
export const HIDE_SM_POPUP = "HIDE_SM_POPUP";
export const LOGOUT = "LOGOUT"; 
export const SET_LOADER_CART = "SET_LOADER_CART";  
export const ORDER_PLACED = "ORDER_PLACED";  
export const TRIGGERED_MOBILE_POPUP = "TRIGGERED_MOBILE_POPUP";  
export const SET_EXPIRED_LINK_DETAILS = "SET_EXPIRED_LINK_DETAILS";  
export const UNEXPECTED_ERROR = "UNEXPECTED_ERROR";  
export const FETCH_ORDERS = "FETCH_ORDERS";  
export const FETCH_ORDER = "FETCH_ORDER";  
export const SHOW_PRODUCT_OUT_OF_STOCK = "SHOW_PRODUCT_OUT_OF_STOCK";  
export const HIDE_PRODUCT_OUT_OF_STOCK = "HIDE_PRODUCT_OUT_OF_STOCK";  
export const FETCH_BROWSE_PRODUCTS_PAGINATION = "FETCH_BROWSE_PRODUCTS_PAGINATION";
export const FETCH_CATEGORY_PRODUCTS_PAGINATION = "FETCH_CATEGORY_PRODUCTS_PAGINATION";
export const FETCH_CATEGORY_PRODUCTS_MAGENTO = "FETCH_CATEGORY_PRODUCTS_MAGENTO";   
export const FETCH_BROWSE_PRODUCTS_MAGENTO = "FETCH_BROWSE_PRODUCTS_MAGENTO";   
export const FETCH_CATEGORY_PRODUCTS_MAGENTO_PAGINATION = "FETCH_CATEGORY_PRODUCTS_MAGENTO_PAGINATION";   
export const FETCH_BROWSE_PRODUCTS_MAGENTO_PAGINATION = "FETCH_BROWSE_PRODUCTS_MAGENTO_PAGINATION";   
export const DISABLE_CHECKOUT_BUTTON = "DISABLE_CHECKOUT_BUTTON";   
export const ENABLE_CHECKOUT_BUTTON = "ENABLE_CHECKOUT_BUTTON";
export const UPDATE_PRODUCT_QTY = "UPDATE_PRODUCT_QTY";
export const SHOW_SUPPLIED_BY_FOOTER = "SHOW_SUPPLIED_BY_FOOTER";
export const HIDE_SUPPLIED_BY_FOOTER = "HIDE_SUPPLIED_BY_FOOTER";
export const SHOW_SUPPLIED_BY_POPUP = "SHOW_SUPPLIED_BY_POPUP";
export const HIDE_SUPPLIED_BY_POPUP = "HIDE_SUPPLIED_BY_POPUP";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";
export const ENTERED_HOME_PAGE = "ENTERED_HOME_PAGE";
export const LEAVING_HOME_PAGE = "LEAVING_HOME_PAGE";
export const SET_AFFILIATE_SELLER_ID = "SET_AFFILIATE_SELLER_ID";
export const SAVE_GIFT_CARD_DETAILS = "SAVE_GIFT_CARD_DETAILS";
export const REVIEW_GIFT_CARD_DETAILS = "REVIEW_GIFT_CARD_DETAILS";
export const CLEAR_GIFT_CARD_DETAILS = "CLEAR_GIFT_CARD_DETAILS";
export const SAVE_GIFT_CARD_SEND_TIME = "SAVE_GIFT_CARD_SEND_TIME";
export const FETCH_GIFT_CARD_DETAILS = "FETCH_GIFT_CARD_DETAILS";
export const FETCH_GIFT_CARD_DETAILS_BY_ID = "FETCH_GIFT_CARD_DETAILS_BY_ID";
export const REMOVE_GIFT_CARD_DETAILS_BY_CART_ITEM_ID = "REMOVE_GIFT_CARD_DETAILS_BY_CART_ITEM_ID";
export const HIDE_UNEXPECTED_ERROR = "HIDE_UNEXPECTED_ERROR";
export const UNSET_JWT = "UNSET_JWT";
export const RESET_EXPIRED_LINK_DETAILS = "RESET_EXPIRED_LINK_DETAILS";
export const HIDE_OFFLINE_POPUP = "HIDE_OFFLINE_POPUP";
export const FETCH_MMS_TEMPLATES = "FETCH_MMS_TEMPLATES";
export const SAVE_GIFT_CARD_MMS_TEMPLATE = "SAVE_GIFT_CARD_MMS_TEMPLATE";
export const FETCH_PASSES = "FETCH_PASSES";
export const SAVE_GIFT_CARD_PASS = "SAVE_GIFT_CARD_PASS";
export const SHOW_MESSAGE_POPUP = "SHOW_MESSAGE_POPUP";
export const HIDE_MESSAGE_POPUP = "HIDE_MESSAGE_POPUP";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const UPDATE_LOCALSTORAGE = "UPDATE_LOCALSTORAGE";
export const FETCH_LOCALSTORAGE_PRODUCTS = "FETCH_LOCALSTORAGE_PRODUCTS";
