import React from "react";
import { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime } from "../../actions";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import requireAuth from "../requireAuth"; 
import history from "../../history";
import { v4 as uuidv4 } from 'uuid';
import { getDatetime } from "../../helper/date";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class GiftCardSchedulePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      product_uuid: null,
      gift_card_details_id: null,
      calendar_value: new Date(),
      calendar_timezone: 'Australia/Sydney',
      hours: new Date().getHours().toString().padStart(2, '0'),
      minutes: new Date().getMinutes().toString().padStart(2, '0'),
      date_message: null,
      products: {}
    }
  }

  componentDidMount = () => {
    const product_uuid = this.props.match.params.productuuid
    const existingProductsJSON = localStorage.gift_card_details;
    const existingProducts = existingProductsJSON ? JSON.parse(existingProductsJSON) : {};
    const existingProduct = existingProducts[product_uuid]
    const gift_card_details_id = existingProduct.id

    this.setState({ product_uuid: product_uuid, gift_card_details_id: gift_card_details_id, products: existingProducts})
    if(existingProduct.scheduled_delivery)
      this.setState({calendar_value: new Date(existingProduct.scheduled_delivery)})
  }

  getTimeZoneOffset = (date, timeZone) => {

    // Abuse the Intl API to get a local ISO 8601 string for a given time zone.
    const options = {timeZone, calendar: 'iso8601', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false};
    const dateTimeFormat = new Intl.DateTimeFormat(undefined, options);
    const parts = dateTimeFormat.formatToParts(date);
    const map = new Map(parts.map(x => [x.type, x.value]));
    const year = map.get('year');
    const month = map.get('month');
    const day = map.get('day');
    const hour = map.get('hour') == 24 ? "00" : map.get('hour');
    const minute = map.get('minute');
    const second = map.get('second');
    const ms = date.getMilliseconds().toString().padStart(3, '0');
    const iso = `${year}-${month}-${day}T${hour}:${minute}:${second}.${ms}`;
  
    // Lie to the Date object constructor that it's a UTC time.
    const lie = new Date(iso + 'Z');
  
    // Return the difference in timestamps, as minutes
    // Positive values are West of GMT, opposite of ISO 8601
    // this matches the output of `Date.getTimeZoneOffset`
    return -(lie - date) / 60 / 1000;
  }

  saveGiftCardSendTime = () => {
    console.log('validate datetime')

    console.log('calendar_timezone', this.state.calendar_timezone)
    console.log('calendar_value', this.state.calendar_value)
    console.log('hours', this.state.hours)
    console.log('minutes', this.state.minutes)

    const timezone = this.state.calendar_timezone
    const calendar_value = this.state.calendar_value
    const hours = this.state.hours
    const minutes = this.state.minutes

    const year = calendar_value.getFullYear()
    const month = (calendar_value.getMonth() + 1).toString().padStart(2, '0')
    const date = calendar_value.getDate().toString().padStart(2, '0')

    const timezone_datetime = `${year}-${month}-${date} ${hours}:${minutes}:00` 

    const timezone_date = new Date(timezone_datetime)

    const seconds_offset = this.getTimeZoneOffset(timezone_date, timezone) * 60

    console.log('timezone_datetime', timezone_datetime)
    console.log('timezone_date', timezone_date)
    console.log('seconds_offset', seconds_offset)

    let utc_date = new Date(timezone_datetime)

    // convert from timezeont ime to utc
    utc_date.setSeconds(utc_date.getSeconds() + seconds_offset);

    console.log('utc_date', utc_date)

    let datetime = getDatetime(utc_date)

    // validate that no before now
    const current_timezone_offset = - new Date().getTimezoneOffset() * 60
    let current_timezone_date = new Date(utc_date.getTime());
    current_timezone_date.setSeconds(current_timezone_date.getSeconds() + current_timezone_offset);

    console.log('utc_date', utc_date)
    console.log('current_timezone_offset', current_timezone_offset)
    console.log('current_timezone_date', current_timezone_date)
    console.log('new Date()', new Date())

    if(new Date() > current_timezone_date) {
      datetime = getDatetime(new Date())
    }
    console.log('datetime',datetime)
    const currentProducts = this.state.products
    const currentProduct = currentProducts[this.state.product_uuid]
    currentProduct.scheduled_delivery = datetime
    currentProduct.timezone = timezone
    localStorage.gift_card_details = JSON.stringify(currentProducts);

    this.props.saveGiftCardSendTime(this.state.gift_card_details_id, datetime, timezone)
    //history.push(`/giftcard/mmstemplates/${this.state.gift_card_details_id}`)
    history.push(`/giftcard/sendnoworschedule/${this.state.gift_card_details_id}/${this.state.product_uuid}`)
  }

  render() {
    return ( 
      <div id="page-gift-card-schedule"> 
        <h1 className="h1">Schedule Gift Card</h1>
        <div className="form">
          <div>
            <div className="input-box"> 
              <label>Time Zone</label>
              <select id="schedule-timezone" style={{ width: '100%' }} onChange={(e => {
                this.setState({ calendar_timezone: e.target.value })
              })} defaultValue={this.state.calendar_timezone}>
                <option value="Australia/Sydney">Australia/Sydney</option>
                <option value="Australia/Melbourne">Australia/Melbourne</option>
                <option value="Australia/Brisbane">Australia/Brisbane</option>
                <option value="Australia/Adelaide">Australia/Adelaide</option>
                <option value="Australia/Perth">Australia/Perth</option>
                <option value="Australia/Darwin">Australia/Darwin</option>
                <option value="Australia/Hobart">Australia/Hobart</option>
                <option value="America/New_York">America/New_York</option>
                <option value="America/Chicago">America/Chicago</option>
                <option value="America/Denver">America/Denver</option>
                <option value="America/Los_Angeles">America/Los_Angeles</option>
                <option value="America/Anchorage">America/Anchorage</option>
                <option value="Pacific/Honolulu">Pacific/Honolulu</option>
              </select>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Calendar
                onChange={(value) => {
                  this.setState({ calendar_value: value })
                }}
                value={this.state.calendar_value}
              />
            </div>
          </div>
          <div id="schedule-time" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div className="time-col">
              <div className="time-up-arrow" onClick={() => {
                let hours = parseInt(this.state.hours)
                hours = (hours + 1) % 24
                hours = hours.toString().padStart(2, '0')

                this.setState({ hours: hours })
              }}></div>
              <div className="time-content"><input type="number" min={0} max={23} defaultValue={this.state.hours} value={this.state.hours} onChange={(e) => {
                const value = e.target.value.toString().padStart(2, '0')

                this.setState({ hours: value })
              }}/></div>
              <div className="time-down-arrow" onClick={() => {
                let hours = parseInt(this.state.hours)
                hours = hours - 1
                if(hours < 0) {
                  hours = 23
                }
                hours = hours.toString().padStart(2, '0')

                this.setState({ hours: hours })
              }}></div>
            </div>
            <div className="time-col">
              <div className="time-colon">:</div>
            </div>
            <div className="time-col">
              <div className="time-up-arrow" onClick={() => {
                let minutes = parseInt(this.state.minutes)
                minutes = (minutes + 1) % 60
                minutes = minutes.toString().padStart(2, '0')

                this.setState({ minutes: minutes })
              }}></div>
              <div className="time-content"><input type="number" min={0} max={59}  defaultValue={this.state.minutes} value={this.state.minutes} onChange={(e) => {
                const value = e.target.value.toString().padStart(2, '0')

                this.setState({ minutes: value })
              }}/></div>
              <div className="time-down-arrow" onClick={() => {
                let minutes = parseInt(this.state.minutes)
                minutes = minutes - 1
                if(minutes < 0) {
                  minutes = 59
                }
                minutes = minutes.toString().padStart(2, '0')

                this.setState({ minutes: minutes })
              }}></div>
            </div>
          </div>
          <div style={{
            textAlign: 'center',
            fontSize: '14px'
          }}>{this.state.date_message}</div>
          <div id="schedule-action-buttons">
            <div className="schedule-button-parent">
              <button type="button" className="schedule-button" onClick={() => {
                this.saveGiftCardSendTime()
              }}>Continue</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    gift_card_details: state.gift_card_details.gift_card_details
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime }
  )(GiftCardSchedulePage) 
);
