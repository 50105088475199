import React from "react";
import { connect } from "react-redux";
import { sendMobileCode, confirmMobileCode, hideMobilePopup, verifyToken, setLoader, createEmptyCart, setEmptyCart, fetchCart, setSelectedShippingAddress, inputValidation, clearValidation } from "../actions";
import history from "../history";
import { Field, reduxForm, change } from "redux-form";
import { getReCaptchaToken } from "../helper/recaptcha"

class MobileCodePopup extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      sendMobileCode: true,
      confirmMobileCode: false,
      confirmedMobileCode: false,
      mobile: null 
    }
  }

  componentDidMount = () => {
    console.log('this.props.expired_link: ', this.props.expired_link) 
    if(this.props.expired_link.status) {
      this.setState({ sendMobileCode: false, confirmMobileCode: true, mobile: this.props.expired_link.mobile })
    }
  }

  renderInput = ({ input, label, type, id }) => {
    return (
      <div>
        <input {...input} autoFocus id={id} type={type} placeholder={label}/>
      </div>
    );
  };

  handleMobileSubmit = (formValues) => {
    console.log(formValues)
    const mobile = formValues.mobile
    console.log(mobile)

    let input_validation = {}
    var reg = /^\+?\d+$/;

    // mobile
    if(!mobile || mobile.trim().length === 0 || !reg.test(mobile)) {
      input_validation.mobile = ["Please enter a valid mobile"] 
    }
    
    console.log('input_validation', input_validation)
    if(Object.keys(input_validation).length > 0) {  
      this.props.inputValidation(input_validation)
    } else {
      this.props.setLoader(true, "Sending Code...")
      getReCaptchaToken('login', (token) => {
        const data = {
          mobile: mobile,
          r_token: token
        }
        this.props.sendMobileCode(data, () => { 
          this.props.clearValidation()
          this.props.setLoader(false, null)
          this.setState({ sendMobileCode: false, confirmMobileCode: true, mobile: mobile })
          this.props.reset() 
        })
      })
    }
  }

  handleCodeSubmit = (formValues) => {
    console.log(formValues)
    const code = formValues.code
    console.log(code)

    let input_validation = {}

    // code
    if(!code || code.trim().length === 0) {
      input_validation.code = ["Please enter a valid code"] 
    }
    
    console.log('input_validation', input_validation)
    if(Object.keys(input_validation).length > 0) {  
      this.props.inputValidation(input_validation)
    } else {
      let data;
      if(this.props.expired_link.status) {
        data = {
          mobile: this.state.mobile,
          code: code,
          expired: true,
          expired_jwt: this.props.jwt 
        }
      } else {
        data = {
          mobile: this.state.mobile,
          code: code,
          expired: false,
          expired_jwt: null
        }
      }
      

      this.props.setLoader(true, "Confirming Code...")
      getReCaptchaToken('login', (token) => {
        data['r_token'] = token 

        this.props.confirmMobileCode(data, (jwt) => {
          this.props.clearValidation()
          this.props.setLoader(false, null)
          this.setState({ confirmMobileCode: false, confirmedMobileCode: true })
          setTimeout(() => {
            this.props.hideMobilePopup()
            if(this.props.expired_link.status) {
              localStorage.autologin = false 
              history.push(`/autologin/${jwt}`)
            } else {
              this.props.setLoader(true, "Verifying Token...")
              this.props.verifyToken(jwt, false, (data) => {
                localStorage.country_code = data.country_code
                localStorage.currency_rate = JSON.stringify(data.currency_rate)
                localStorage.mobile = this.state.mobile
                this.props.setLoader(false, null)
                if(data.new_user) {
                  this.props.setLoader(false, null)  
                  history.push('/register')
                } 
              })
            }
            
          }, 2000)
        })
      })
    }
  }

  render() {
    let sendMobileCode;
    let confirmMobileCode;
    let confirmedMobileCode;

    if(this.state.sendMobileCode) {
      let mobileMessage = this.props.input_validation.mobile.map(message => 
        <div key={message} className="input-validation">{message}</div>);
      let mobileCodeMessage = this.props.input_validation.mobile_code.map(message => 
        <div key={message} className="input-validation">{message}</div>); 

      sendMobileCode = (
        <React.Fragment>
          <form
            onSubmit={this.props.handleSubmit(this.handleMobileSubmit)} 
            className="form"
          >
            <div className="mobile-popup-heading">Send Mobile Code</div>
            <div className="input-box"> 
              <Field
                name="mobile"
                component={this.renderInput}
                label="Your mobile"
                type="text"
              />
              {mobileMessage}
              {mobileCodeMessage}
            </div>
            <button>Send Code</button>
          </form>
        </React.Fragment>
      )
    } else if(this.state.confirmMobileCode) {
      let codeMessage = this.props.input_validation.code.map(message => 
        <div key={message} className="input-validation">{message}</div>);
      let mobileCodeMessage = this.props.input_validation.mobile_code.map(message => 
        <div key={message} className="input-validation">{message}</div>); 

      confirmMobileCode = (
        <React.Fragment>
          <form
            onSubmit={this.props.handleSubmit(this.handleCodeSubmit)} 
            className="form"
          >
            <div className="mobile-popup-heading">Confirm Mobile Code</div>
            <div className="input-box"> 
              <Field
                name="code"
                component={this.renderInput}
                label="Enter Code"
                type="text"
              />
              {codeMessage}
              {mobileCodeMessage}
            </div>
            <button>Confirm Code</button>
          </form>
        </React.Fragment>
      )
    } else if(this.state.confirmedMobileCode) {
      confirmedMobileCode = (
        <React.Fragment>
          <div className="mobile-popup-heading">Success</div>
          <div>Mobile code confirmed</div>
        </React.Fragment>
      )
    }

    return (
      <div id="mobile-popup-mask">
        <div id="mobile-popup">
          <div 
            id="mobile-popup-x"
            onClick={() => {
              this.props.hideMobilePopup()
            }}
          >x</div>
          {sendMobileCode}
          {confirmMobileCode}
          {confirmedMobileCode}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    shipping_addresses: state.shipping_addresses,
    input_validation: state.input_validation,
    expired_link: state.expired_link,
    jwt: state.jwt,
  };
};


const mobileCodePopup = reduxForm({
  form: "mobile-code-popup"
})(MobileCodePopup); 

export default connect(
  mapStateToProps,
  { sendMobileCode, confirmMobileCode, hideMobilePopup, verifyToken, setLoader, createEmptyCart, setEmptyCart, fetchCart, setSelectedShippingAddress, inputValidation, clearValidation }
)(mobileCodePopup);
