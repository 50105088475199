import {
  INPUT_VALIDATION,
  CLEAR_VALIDATION
} from "../actions/types";

const INITIAL_STATE = {
  mobile: [],
  fullname: [],
  email: [],
  address: [],
  street_address: [],
  suburb: [],
  state: [],
  country: [],
  postcode: [],
  terms_and_privacy: [],
  card: [],
  code: [],
  register: [],
  mobile_code: [],
  firstname: [],
  lastname: [],
  message: [],
  purchaser_mobile: [],
  text_content: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_VALIDATION: 
      state = INITIAL_STATE
      return {
        ...state,
      };
    case INPUT_VALIDATION:
      state = INITIAL_STATE
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
