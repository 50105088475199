import React from "react";
import { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime, fetchProduct, fetchGiftCardDetailsById, fetchMMSTemplates, saveGiftCardMMSTemplate } from "../../actions"
import { connect } from "react-redux";
import requireAuth from "../requireAuth"; 
import history from "../../history";
import CONSTANTS from "../../constants"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

class GiftCardMMSTemplatesPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      uuid: null,
      gift_card_details_id: null,
      product_name: null,
      gift_card_price: null,
      product_image: null,
      currentSlide: 0
    }
  }

  componentDidMount = () => {
    const gift_card_details_id = this.props.match.params.giftcarddetailsid
    const product_uuid = this.props.match.params.productuuid

    this.setState({ gift_card_details_id: gift_card_details_id, product_uuid: product_uuid })

    if(this.props.auth.is_signed_in) {
      this.props.fetchMMSTemplates(gift_card_details_id)
    }
  }



  render() {
    return (
      <div id="page-gift-card-mms-templates"> 
        <h1 className="h1">How would you like your gift wrapped?</h1>
        <div>
          <div className="product-gift-card-mms-templates-container">
            <div id="gift-card-mms-templates">
              <div>
                <Carousel
                  showArrows
                  showStatus={false}
                  renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                    hasPrev && (
                      <span className="carousel-arrow carousel-arrow-prev"  onClick={clickHandler}>
                  <FaChevronLeft size={30} />
                </span>
                    )
                  }
                  renderArrowNext={(clickHandler, hasNext, labelNext) =>
                    hasNext && (
                      <span className="carousel-arrow carousel-arrow-next" onClick={clickHandler}>
                  <FaChevronRight size={30} />
                </span>
                    )
                  }
                  showIndicators={false}
                  showThumbs={false}
                >
                  {this.props.mms_templates.map((v) => (
                    <div className="gift-card-mms-template-parent" key={v.mms_template_id}>
                      <img className="gift-card-mms-template" src={v.mms_template_url}/>
                      <div className="gift-card-mms-template-button-parent" style={{paddingBottom: '20px'}}>
                        <button className="gift-card-mms-template-button" onClick={() => {
                          const existingProductsJSON = localStorage.gift_card_details;
                          const existingProducts = existingProductsJSON ? JSON.parse(existingProductsJSON) : {};
                          const existingProduct = existingProducts[this.state.product_uuid]
                          existingProduct.mms_template_id = v.mms_template_id
                          existingProduct.click2_giftcard_mms_template_id = v.id
                          existingProduct.mms_template_url = v.mms_template_url
                          localStorage.gift_card_details = JSON.stringify(existingProducts);
                          history.push(`/giftcard/schedule/`+this.state.gift_card_details_id+'/'+this.state.product_uuid)
                        }}>Continue</button>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    gift_card_details: state.gift_card_details.gift_card_details,
    product: state.product.product,
    mms_templates: state.mms_templates.mms_templates 
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime, fetchProduct, fetchGiftCardDetailsById, fetchMMSTemplates, saveGiftCardMMSTemplate }
  )(GiftCardMMSTemplatesPage) 
);
