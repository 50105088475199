import React from "react";
import { connect } from "react-redux";
import { showMobilePopup, triggeredMobilePopup, clearProduct } from "../actions";
import history from "../history";
import CONSTANTS from "../constants";
import {isTap2Buy, isGiftGuru, convertPrice, getWithExpiry} from "../helper/common";

class Product extends React.Component {
  constructor(props) {
    super(props);

    const qty = props.qty ? props.qty : 1;
    let selected_size = null;
    let selected_color = null;
    let selected_gift_card_price = null;
    if (props.options) {
      selected_size = props.options.size ? props.options.size : null;
      selected_color = props.options.color ? props.options.color : null;
      selected_gift_card_price = props.options.gift_card_price
        ? props.options.gift_card_price
        : null;
    }

    this.state = {
      showShippingInfo: false,
      showSizingChart: false,
      showFAQs: false,
      qty: qty,
      selected_size: selected_size,
      selected_color: selected_color,
      selected_gift_card_price: selected_gift_card_price,
      isGiftCard: false,
      configurable_error_messages: [],
      in_stock: true,
      height: 0,
    };

    this.productImage = React.createRef();
  }

  updateDimensions = () => {
    const width = this.productImage.current.offsetWidth;

    const temp_height =  width * 0.62;

    this.setState({ height: temp_height });
  };

  componentDidMount = () => {
    this.updateDimensions();

    setTimeout(() => {
      this.updateDimensions();
    }, 10)

    window.addEventListener("resize", this.updateDimensions);

    if (
      this.props.product.configurable_options &&
      this.props.product.configurable_options.gift_card_price &&
      this.props.product.configurable_options.gift_card_price.options.length > 0
    ) {
      const initial_selected_gift_card =
        this.props.product.configurable_options.gift_card_price.options[0];
      this.setState({
        selected_gift_card_price: initial_selected_gift_card,
        in_stock: initial_selected_gift_card.qty > 0,
        isGiftCard: true,
      });
    } else {
      this.setState({ in_stock: this.props.product.qty > 0 });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    this.props.clearProduct()
  }

  getProductDescription = (raw_desc) => {
    return raw_desc.substring(3, raw_desc.lastIndexOf("<"));
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  onHandleProductAction = () => {
    if (this.props.product.type_id == "configurable") {
      let configurable_error_messages = [];

      // color
      let color_option = null;
      if (this.props.product.configurable_options.color) {
        if (!this.state.selected_color) {
          configurable_error_messages.push("You must select a color");
        } else {
          color_option = {
            option_id: this.props.product.configurable_options.color.option_id,
            option_value: this.state.selected_color.option_value,
          };
        }
      }

      // size
      let size_option = null;
      if (this.props.product.configurable_options.size) {
        if (!this.state.selected_size) {
          configurable_error_messages.push("You must select a size");
        } else {
          size_option = {
            option_id: this.props.product.configurable_options.size.option_id,
            option_value: this.state.selected_size.option_value,
          };
        }
      }

      // gift card price
      let gift_card_price_option = null;
      if (this.props.product.configurable_options.gift_card_price) {
        if (!this.state.selected_gift_card_price) {
          configurable_error_messages.push("You must select a Gift Card price");
        } else {
          gift_card_price_option = {
            option_id:
              this.props.product.configurable_options.gift_card_price.option_id,
            option_value: this.state.selected_gift_card_price.option_value,
            price: this.state.selected_gift_card_price.default_title
          };
        }
      }

      // get configurable product sku
      let configurable_product_sku = null;
      if (this.props.product.configurable_options_products) {
        // prep
        let colors = null;
        if (
          this.props.product.configurable_options_products[
            CONSTANTS.COLOR_OPTION_ID
          ]
        ) {
          colors =
            this.props.product.configurable_options_products[
              CONSTANTS.COLOR_OPTION_ID
            ];
        }
        let sizes = null;
        if (
          this.props.product.configurable_options_products[
            CONSTANTS.SIZE_OPTION_ID
          ]
        ) {
          sizes =
            this.props.product.configurable_options_products[
              CONSTANTS.SIZE_OPTION_ID
            ];
        }
        let gift_card_prices = null;
        if (
          this.props.product.configurable_options_products[
            CONSTANTS.GIFT_CARD_PRICE_OPTION_ID
          ]
        ) {
          gift_card_prices =
            this.props.product.configurable_options_products[
              CONSTANTS.GIFT_CARD_PRICE_OPTION_ID
            ];
        }

        // both color and sizes
        if (colors && sizes) {
          colors.forEach((v_color) => {
            sizes.forEach((v_size) => {
              if (
                v_color.value_index == this.state.selected_color.option_value
              ) {
                if (
                  v_size.value_index == this.state.selected_size.option_value
                ) {
                  if (v_size.sku == v_color.sku) {
                    configurable_product_sku = v_size.sku;
                  }
                }
              }
            });
          });
        } else if (colors) {
          colors.forEach((v) => {
            if (v.value_index == this.state.selected_color.option_value) {
              configurable_product_sku = v.sku;
            }
          });
        } else if (sizes) {
          sizes.forEach((v) => {
            if (v.value_index == this.state.selected_size.option_value) {
              configurable_product_sku = v.sku;
            }
          });
        } else if (gift_card_prices) {
          gift_card_prices.forEach((v) => {
            if (
              v.value_index == this.state.selected_gift_card_price.option_value
            ) {
              configurable_product_sku = v.sku;
            }
          });
        }
      }

      if (configurable_error_messages.length > 0) {
        this.setState({
          configurable_error_messages: configurable_error_messages,
        });
      } else {
        this.props.handleProductAction(
          this.props.product,
          this.state.qty,
          {
            color: color_option,
            size: size_option,
            gift_card_price: gift_card_price_option,
          },
          configurable_product_sku
        );
      }
    } else {
      this.props.handleProductAction(
        this.props.product,
        this.state.qty,
        null,
        null
      );
    }
  };

  checkColorExistsForSize = (color_option_value) => {
    if(this.state.selected_size) {
      const size_option_id = this.props.product.configurable_options.size.option_id
      const size_option_value = this.state.selected_size.option_value

      const product_skus = this.props.product.configurable_options_products[size_option_id].filter(v2 => v2.value_index == size_option_value)

      for(let i = 0; i < product_skus.length; i++) {
        const product_sku = product_skus[i].sku
        const option_exists = this.props.product.configurable_products[product_sku]['93'] ? this.props.product.configurable_products[product_sku]['93'].find(v => (v.option_value == color_option_value && v.qty > 0)) : false
        if(option_exists) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }

  checkSizeExistsForColor = (size_option_value) => {
    if(this.state.selected_color) {
      const color_option_id = this.props.product.configurable_options.color.option_id
      const color_option_value = this.state.selected_color.option_value
      const product_skus = this.props.product.configurable_options_products[color_option_id].filter(v2 => v2.value_index == color_option_value)

      for(let i = 0; i < product_skus.length; i++) {
        const product_sku = product_skus[i].sku
        const option_exists = this.props.product.configurable_products[product_sku]['141'] ? this.props.product.configurable_products[product_sku]['141'].find(v => (v.option_value == size_option_value && v.qty > 0)) : false
        if(option_exists) {
          return true
        }
      }
      return false
    } else {
      return true
    }
    
  }

  render = () => {
    // set price
    let price = parseFloat(this.props.product.price).toFixed(2);
    if (this.state.selected_gift_card_price) {
      price = parseFloat(
        this.state.selected_gift_card_price.default_title
      ).toFixed(2);
      price = this.numberWithCommas(price);
    }

    const country_code = localStorage.getItem('country_code') || 'AU';
    const currency_rate = localStorage.getItem('currency_rate')
      ? JSON.parse(localStorage.getItem('currency_rate'))
      : {};
    const currencySymbol = country_code === 'AU' ? 'AUD' : 'USD';
    price = convertPrice(price, country_code, currency_rate);

    // let description = this.props.product.description
    // let first_index = description.indexOf('>') + 1
    // let last_index = description.lastIndexOf('<')
    // description = description.substring(first_index, last_index)

    // set button text
    let button_text = "Add to Cart";
    if (this.props.update) {
      button_text = "Update Cart";
    }

    let configurable_options = null;
    if (this.props.product.configurable_options.length !== 0) {
      let configurable_option_color = null;
      if (this.props.product.configurable_options.color) {
        let colors = this.props.product.configurable_options.color.options.map(
          (v, i) => {
            let selected_class = null;
            if (this.state.selected_color) {
              if (this.state.selected_color.option_value == v.option_value) {
                selected_class = "selected";
              }
            }

            return (
              <div
                key={i}
                className={`swatch-option ${selected_class}`}
                style={{ background: v.default_title.toLowerCase() }}
                onClick={() => {
                  this.setState({ selected_color: v });
                  if(!this.checkColorExistsForSize(v.option_value)) {
                    this.setState({ selected_size: null });
                  }
                }}
              ></div>
            );
          }
        );

        let selected_color = null;
        if (this.state.selected_color) {
          selected_color = this.state.selected_color.default_title;
        }

        configurable_option_color = (
          <div className="swatch-attribute">
            <div>
              <span className="swatch-attribute-label">Color</span>
              <span class="swatch-attribute-selected-option">
                {selected_color}
              </span>
            </div>
            <div className="swatch-attribute-options">{colors}</div>
          </div>
        );
      }

      let configurable_option_size = null;
      if (this.props.product.configurable_options.size) {
        let sizes = this.props.product.configurable_options.size.options.map(
          (v) => {
            let classes = '';
            if (this.state.selected_size) {
              if (this.state.selected_size.option_value == v.option_value) {
                classes = "selected";
              }
            }

            let option_exists = true
            if(this.props.product.configurable_options.color && this.state.selected_color) {
              option_exists = this.checkSizeExistsForColor(v.option_value)
              if(!option_exists) {
                classes += ' swatch-option-not-available'
              }
            }

            return (
              <div
                className={`swatch-option ${classes}`}
                onClick={() => {
                  if(option_exists) {
                    this.setState({ selected_size: v });
                  }
                }}
              >
                {v.default_title}
              </div>
            );
          }
        );

        let selected_size = null;
        if (this.state.selected_size) {
          selected_size = this.state.selected_size.default_title;
        }

        configurable_option_size = (
          <div className="swatch-attribute">
            <div>
              <span className="swatch-attribute-label">Size</span>
              <span class="swatch-attribute-selected-option">
                {selected_size}
              </span>
            </div>
            <div className="swatch-attribute-options">{sizes}</div>
          </div>
        );
      }

      let configurable_option_gift_card_price = null;
      if (this.props.product.configurable_options.gift_card_price) {
        let gift_cart_prices_options =
          this.props.product.configurable_options.gift_card_price.options.map(
            (v, i) => {
              return (
                <option key={i} value={v.option_value}>
                  ${convertPrice(this.numberWithCommas(v.default_title), country_code, currency_rate)}
                </option>
              );
            }
          );

        let selected_gift_card_price = null;
        if (this.state.selected_gift_card_price) {
          selected_gift_card_price =
            this.state.selected_gift_card_price.default_title;
        }

        configurable_option_gift_card_price = (
          <div className="swatch-attribute">
            <div>
              <span className="swatch-attribute-label">Price</span>
            </div>
            <div className="swatch-attribute-options">
              <select
                id="gift-card-prices-select"
                onChange={(e) => {
                  const value = e.target.value;

                  const gift_card_price =
                    this.props.product.configurable_options.gift_card_price.options.find(
                      (v) => value === v.option_value
                    );

                  this.setState({
                    selected_gift_card_price: gift_card_price,
                    in_stock: gift_card_price.qty > 0,
                  });
                }}
              >
                {gift_cart_prices_options}
              </select>
              {/* {gift_card_prices} */}
            </div>
          </div>
        );
      }

      const configurable_error_messages =
        this.state.configurable_error_messages.map((v) => {
          return <div className="input-validation">{v}</div>;
        });

      configurable_options = (
        <div id="product-configurable-options">
          {configurable_option_color}
          {configurable_option_size}
          {configurable_option_gift_card_price}
          {this.state.isGiftCard && (
            <div id="giftcard-add-to-cart-section">
              <div id="giftcard-add-to-cart-parent">
                <button
                  id="giftcard-add-to-cart"
                  onClick={() => {
                    const token = getWithExpiry("token_ttl");
                    console.log('Check token with ttl', token)
                    if (token) {
                      this.onHandleProductAction();
                    } else {
                      this.props.triggeredMobilePopup({
                        pathname: null,
                      });
                      this.props.showMobilePopup();
                    }
                  }}
                >
                  Buy Now
                </button>
              </div>
            </div>
          )}
          {configurable_error_messages}
        </div>
      );
    }

    let seller_img = null;
    if (this.props.seller && this.props.product) {
      let seller_img_url = null;
      if (this.props.product.product_seller_image !== null) {
        seller_img_url = this.props.product.product_seller_image;
      } else {
        seller_img_url = this.props.seller.image;
      }
      seller_img = (
        <img
          onClick={() => {
            history.push(`/sellerprofile/${this.props.seller.shop_url}`);
          }}
          src={seller_img_url}
        />
      );
    }

    return (
      <div id="product">
        {this.state.isGiftCard ? (
          <div id="giftcard-image">
            <div id="product-giftcard-image">
              <img ref={this.productImage} src={this.props.product.images[0]} style={{ height: this.state.height }} />
            </div>
          </div>
        ) : (
          <div id="product-images">
            <div id="product-main-image">
              <img ref={this.productImage} src={this.props.product.images[0]} />
            </div>
            <div id="product-secondary-images">
              <div className="product-secondary-image-parent">
                <img
                  className="product-secondary-image"
                  src={this.props.product.images[1]}
                />
              </div>
              <div className="product-secondary-image-parent">
                <img
                  className="product-secondary-image"
                  src={this.props.product.images[2]}
                />
              </div>
              <div className="product-secondary-image-parent">
                <img
                  className="product-secondary-image"
                  src={this.props.product.images[3]}
                />
              </div>
            </div>
          </div>
        )}
        <div id="product-details">
          <div id="product-details-text">
            <div id="product-name">{this.props.product.name}</div>
            <div id="product-sku-stock">
              {!this.state.isGiftCard && (
                <span id="product-sku">{this.props.product.sku}</span>
              )}
              {this.state.in_stock ? (
                <span
                  id="product-in-stock"
                  style={{ marginLeft: this.state.isGiftCard ? 0 : 10 }}
                >
                  In Stock
                </span>
              ) : (
                <span
                  id="product-out-of-stock"
                  style={{ marginLeft: this.state.isGiftCard ? 0 : 10 }}
                >
                  Out of Stock
                </span>
              )}
            </div>
            <div id="product-price">${price} {currencySymbol}</div>
          </div>
          <div id="product-details-image">{seller_img}</div>
        </div>
        {configurable_options}
        {!this.state.isGiftCard && (
          <div id="product-add-to-cart-section">
            <div id="product-add-to-cart-parent">
              <button
                id="product-add-to-cart"
                onClick={() => {
                  if (this.props.auth.is_signed_in) {
                    this.onHandleProductAction();
                  } else {
                    this.props.triggeredMobilePopup({
                      pathname: null,
                    });
                    this.props.showMobilePopup();
                  }
                }}
              >
                {button_text}
              </button>
            </div>
            {!this.state.isGiftCard && (
              <div id="product-qty-parent">
                <div id="product-qty-control">
                  <div className="qty-minus">
                    <a
                      className="btn-qty minus"
                      onClick={() => {
                        const qty = this.state.qty > 1 ? this.state.qty - 1 : 1;
                        this.setState({ qty: qty });
                      }}
                    >
                      -
                    </a>
                  </div>
                  <div className="qty-input">
                    <input
                      type="number"
                      name="qty"
                      id="qty"
                      maxLength="12"
                      value={this.state.qty}
                      title="Qty"
                      className="input-text qty"
                      onChange={() => {}}
                    />
                  </div>
                  <div className="qty-plus">
                    <a
                      className="btn-qty plus"
                      onClick={() => {
                        this.setState({ qty: this.state.qty + 1 });
                      }}
                    >
                      +
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div id="description-parent">
          <div id="description-title">Description:</div>
          <div id="description">
            {this.getProductDescription(this.props.product.description)}
          </div>
        </div>
        <div id="product-extra-info">{this.renderProductTabs()}</div>
      </div>
    );
  };

  renderProductTabs = () => {
    const tabs = this.props.product.tabs;
    if (tabs) {
      let tab1_jsx = null;
      if (tabs.tab1) {
        const tab1 = tabs.tab1;

        let image1 =
          "https://cdn-staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/shipping-info-country.png";
        if (tab1.row1.image) {
          image1 = `https://cdn-staging.securemobile.net/${tab1.row1.image.filename}`;
        }

        let image2 =
          "https://cdn-staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/shipping-info-truck.png";
        if (tab1.row2.image) {
          image2 = `https://cdn-staging.securemobile.net/${tab1.row2.image.filename}`;
        }

        tab1_jsx = (
          <div className="product-view-content-section">
            <div className="product-view-content-heading">
              {tabs.tab1.title}
              <div
                className="checkout-arrow"
                onClick={() => {
                  this.setState({
                    showShippingInfo: !this.state.showShippingInfo,
                  });
                }}
              ></div>
            </div>
            {this.state.showShippingInfo && (
              <div className="product-view-content">
                <div className="product-view-content-row">
                  <div className="product-view-content-left">
                    <img src={image1} />
                  </div>
                  <div className="product-view-content-right">
                    <p>{tab1.row1.text}</p>
                  </div>
                </div>
                <div className="product-view-content-row">
                  <div className="product-view-content-left">
                    <img src={image2} />
                  </div>
                  <div className="product-view-content-right">
                    <p>{tab1.row2.text}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }

      let tab2_jsx = null;
      if (tabs.tab2) {
        const tab2 = tabs.tab2;

        let image =
          "https://cdn-staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/images/sizing-chart.png";
        if (tab2.image) {
          image = `https://cdn-staging.securemobile.net/${tab2.image.filename}`;
        }

        tab2_jsx = (
          <div className="product-view-content-section">
            <div className="product-view-content-heading">
              {tabs.tab2.title}
              <div
                className="checkout-arrow"
                onClick={() => {
                  this.setState({
                    showSizingChart: !this.state.showSizingChart,
                  });
                }}
              ></div>
            </div>
            {this.state.showSizingChart && (
              <div className="product-view-content" id="tab2-image-parent">
                <img src={image} />
              </div>
            )}
          </div>
        );
      }

      let tab3_jsx = null;
      if (tabs.tab3) {
        const tab3 = tabs.tab3;

        const qas = tab3.qas.map((v, i) => {
          return (
            <div key={`qa=${i}`} className="product-view-content-qa">
              <p>
                <b>{v.question}</b>
              </p>
              <p className="product-view-content-faqs">{v.answer}</p>
            </div>
          );
        });

        tab3_jsx = (
          <div className="product-view-content-section">
            <div className="product-view-content-heading">
              {tabs.tab3.title}
              <div
                className="checkout-arrow"
                onClick={() => {
                  this.setState({ showFAQs: !this.state.showFAQs });
                }}
              ></div>
            </div>
            {this.state.showFAQs && (
              <div className="product-view-content">{qas}</div>
            )}
          </div>
        );
      }

      return (
        <React.Fragment>
          {tab1_jsx}
          {tab2_jsx}
          {tab3_jsx}
        </React.Fragment>
      );
    } else {
      return null;
    }
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  showMobilePopup,
  triggeredMobilePopup,
  clearProduct
})(Product);
