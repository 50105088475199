import React from "react";
import { register, inputValidation, clearValidation, checkEmailExists, setLoader, fetchProduct, fetchSeller, setCustomerData } from "../../actions"; 
import { connect } from "react-redux";
import { Field, reduxForm, change, getFormValues } from "redux-form";
import history from "../../history";
import requireNoAuth from "../requireNoAuth"; 
import { Link } from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import { getReCaptchaToken } from "../../helper/recaptcha"
import { isGiftGuru, isTap2Buy } from "../../helper/common";


class RegistrationPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.addressInput = React.createRef()


    this.state = {
      show_address_fields: false, 
      customer: null,
      show_registration_popup: true,
      regions: [
        {
          "id": "569",
          "code": "ACT",
          "name": "Australian Capital Territory"
        }, 
        {
          "id": "570",
          "code": "NSW",
          "name": "New South Wales"
        },
        {
          "id": "576",
          "code": "NT",
          "name": "Northern Territory"
        },
        {
          "id": "572",
          "code": "QLD",
          "name": "Queensland"
        },
        {
          "id": "573",
          "code": "SA",
          "name": "South Australia"
        },
        {
          "id": "574",
          "code": "TAS",
          "name": "Tasmania"
        },
        {
          "id": "571",
          "code": "VIC",
          "name": "Victoria"
        },
        {
          "id": "575",
          "code": "WA",
          "name": "Western Australia"
        },
        {
          "id": "1",
          "code": "AL",
          "name": "Alabama"
        },
        {
          "id": "2",
          "code": "AK",
          "name": "Alaska"
        },
        {
          "id": "3",
          "code": "AS",
          "name": "American Samoa"
        },
        {
          "id": "4",
          "code": "AZ",
          "name": "Arizona"
        },
        {
          "id": "5",
          "code": "AR",
          "name": "Arkansas"
        },
        {
          "id": "6",
          "code": "AE",
          "name": "Armed Forces Africa"
        },
        {
          "id": "7",
          "code": "AA",
          "name": "Armed Forces Americas"
        },
        {
          "id": "8",
          "code": "AE",
          "name": "Armed Forces Canada"
        },
        {
          "id": "9",
          "code": "AE",
          "name": "Armed Forces Europe"
        },
        {
          "id": "10",
          "code": "AE",
          "name": "Armed Forces Middle East"
        },
        {
          "id": "11",
          "code": "AP",
          "name": "Armed Forces Pacific"
        },
        {
          "id": "12",
          "code": "CA",
          "name": "California"
        },
        {
          "id": "13",
          "code": "CO",
          "name": "Colorado"
        },
        {
          "id": "14",
          "code": "CT",
          "name": "Connecticut"
        },
        {
          "id": "15",
          "code": "DE",
          "name": "Delaware"
        },
        {
          "id": "16",
          "code": "DC",
          "name": "District of Columbia"
        },
        {
          "id": "17",
          "code": "FM",
          "name": "Federated States Of Micronesia"
        },
        {
          "id": "18",
          "code": "FL",
          "name": "Florida"
        },
        {
          "id": "19",
          "code": "GA",
          "name": "Georgia"
        },
        {
          "id": "20",
          "code": "GU",
          "name": "Guam"
        },
        {
          "id": "21",
          "code": "HI",
          "name": "Hawaii"
        },
        {
          "id": "22",
          "code": "ID",
          "name": "Idaho"
        },
        {
          "id": "23",
          "code": "IL",
          "name": "Illinois"
        },
        {
          "id": "24",
          "code": "IN",
          "name": "Indiana"
        },
        {
          "id": "25",
          "code": "IA",
          "name": "Iowa"
        },
        {
          "id": "26",
          "code": "KS",
          "name": "Kansas"
        },
        {
          "id": "27",
          "code": "KY",
          "name": "Kentucky"
        },
        {
          "id": "28",
          "code": "LA",
          "name": "Louisiana"
        },
        {
          "id": "29",
          "code": "ME",
          "name": "Maine"
        },
        {
          "id": "30",
          "code": "MH",
          "name": "Marshall Islands"
        },
        {
          "id": "31",
          "code": "MD",
          "name": "Maryland"
        },
        {
          "id": "32",
          "code": "MA",
          "name": "Massachusetts"
        },
        {
          "id": "33",
          "code": "MI",
          "name": "Michigan"
        },
        {
          "id": "34",
          "code": "MN",
          "name": "Minnesota"
        },
        {
          "id": "35",
          "code": "MS",
          "name": "Mississippi"
        },
        {
          "id": "36",
          "code": "MO",
          "name": "Missouri"
        },
        {
          "id": "37",
          "code": "MT",
          "name": "Montana"
        },
        {
          "id": "38",
          "code": "NE",
          "name": "Nebraska"
        },
        {
          "id": "39",
          "code": "NV",
          "name": "Nevada"
        },
        {
          "id": "40",
          "code": "NH",
          "name": "New Hampshire"
        },
        {
          "id": "41",
          "code": "NJ",
          "name": "New Jersey"
        },
        {
          "id": "42",
          "code": "NM",
          "name": "New Mexico"
        },
        {
          "id": "43",
          "code": "NY",
          "name": "New York"
        },
        {
          "id": "44",
          "code": "NC",
          "name": "North Carolina"
        },
        {
          "id": "45",
          "code": "ND",
          "name": "North Dakota"
        },
        {
          "id": "46",
          "code": "MP",
          "name": "Northern Mariana Islands"
        },
        {
          "id": "47",
          "code": "OH",
          "name": "Ohio"
        },
        {
          "id": "48",
          "code": "OK",
          "name": "Oklahoma"
        },
        {
          "id": "49",
          "code": "OR",
          "name": "Oregon"
        },
        {
          "id": "50",
          "code": "PW",
          "name": "Palau"
        },
        {
          "id": "51",
          "code": "PA",
          "name": "Pennsylvania"
        },
        {
          "id": "52",
          "code": "PR",
          "name": "Puerto Rico"
        },
        {
          "id": "53",
          "code": "RI",
          "name": "Rhode Island"
        },
        {
          "id": "54",
          "code": "SC",
          "name": "South Carolina"
        },
        {
          "id": "55",
          "code": "SD",
          "name": "South Dakota"
        },
        {
          "id": "56",
          "code": "TN",
          "name": "Tennessee"
        },
        {
          "id": "57",
          "code": "TX",
          "name": "Texas"
        },
        {
          "id": "58",
          "code": "UT",
          "name": "Utah"
        },
        {
          "id": "59",
          "code": "VT",
          "name": "Vermont"
        },
        {
          "id": "60",
          "code": "VI",
          "name": "Virgin Islands"
        },
        {
          "id": "61",
          "code": "VA",
          "name": "Virginia"
        },
        {
          "id": "62",
          "code": "WA",
          "name": "Washington"
        },
        {
          "id": "63",
          "code": "WV",
          "name": "West Virginia"
        },
        {
          "id": "64",
          "code": "WI",
          "name": "Wisconsin"
        },
        {
          "id": "65",
          "code": "WY",
          "name": "Wyoming"
        }


      ]
    }
  }

//   mobile: "0400953507",
//   email: "cameron@mobile.digital",
//   address: {
//     street_address: "10 Bonita Court", 
//     suburb: "Mudgeeraba",
//     state: "QLD",
//     country: "Australia",
//     postcode: "4213"
//   } 
// } 

  componentDidMount = () => {    
    if(this.props.customer) {
      this.setState({ customer: this.props.customer })
      let customer = this.props.customer
      const customer_mobile = customer.mobile ? customer.mobile : ""
      const customer_fullname = customer.fullname ? customer.fullname.trim() : "" 
      const customer_email = customer.email ? customer.email : ""
      let customer_street_address = ""
      let customer_suburb = ""
      let customer_state = ""
      let customer_country = ""
      let customer_postcode = ""
      let region_id = ""
      let country_id = "AU"
      if(customer.address) {
        let address = customer.address
        customer_street_address = address.street_address ? address.street_address : ""
        customer_suburb = address.suburb ? address.suburb : ""
        customer_country = address.country === "Australia" ? "Australia" : "America"
        customer_postcode = address.postcode ? address.postcode : ""

        customer_state = address.state ? address.state : ""

        region_id = null;
        this.state.regions.forEach((v) => {
          if(v.name.toLowerCase() === customer_state.toLowerCase()) {
            region_id = v.id
          }
        })
      }
      let customer_address = customer_street_address + " " + customer_suburb + " " + customer_state + " " + customer_country + " " + customer_postcode
      if (customer_address.trim().length === 0) {
        customer_address = ""
      }
      customer_address = customer_address.trim()
      this.props.initialize({ 
        mobile: customer_mobile,
        fullname: customer_fullname,
        email: customer_email,
        address: customer_address,
        street_address: customer_street_address,
        suburb: customer_suburb,
        state: region_id,
        country: country_id,
        postcode: customer_postcode
      });
    }
    
    if(localStorage.product_id) { 
      this.props.fetchProduct(localStorage.product_id, () => { 
      })
      this.props.fetchSeller(localStorage.product_id, () => {
      })
    }
  }

  componentWillUnmount = () => {
    if(this.props.form_values) {
      const customer_data = {
        mobile: this.props.form_values.mobile,
        fullname: this.props.form_values.fullname,
        email: this.props.form_values.email,
        address: {
          street_address: this.props.form_values.street_address,
          suburb: this.props.form_values.suburb,
          country: this.props.form_values.country,
          postcode: this.props.form_values.postcode,
          state: this.props.form_values.state,
        }
      }
      this.props.setCustomerData(customer_data)
    }
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleSubmit = async (formValues) => {
    let input_validation = {}
    let show_address_fields = false;

    // mobile
    if(!formValues.mobile || formValues.mobile.trim().length === 0) {
      input_validation.mobile = ["Please enter a valid mobile"]
    }
    // fullname
    if(!formValues.fullname || formValues.fullname.trim().length === 0 || formValues.fullname.trim().split(' ').length < 2) {
      input_validation.fullname = ["Please enter your full name"]
    }
    // email
    if(!formValues.email || formValues.email.trim().length === 0 || !this.validateEmail(formValues.email)) {
      input_validation.email = ["Please enter a valid email"]
    }
    // street_address
    if(!formValues.street_address || formValues.street_address.trim().length === 0) {
      input_validation.street_address = ["Missing street address"]
      show_address_fields = true;
    }
    // suburb
    if(!formValues.suburb || formValues.suburb.trim().length === 0) {
      input_validation.suburb = ["Missing suburb"]
      show_address_fields = true;
    }
    // state
    if(!formValues.state || formValues.state.trim().length === 0 || formValues.state === "Enter State") {
      input_validation.state = ["Missing state"]
      show_address_fields = true;
    }
    // country
    if(!formValues.country || formValues.country.trim().length === 0) {
      input_validation.country = ["Missing country"]
      show_address_fields = true;
    }
    // postcode
    if(!formValues.postcode || formValues.postcode.trim().length === 0) {
      input_validation.postcode = ["Missing postcode"] 
      show_address_fields = true;
    }
    // terms
    if(!formValues.termsAndPrivacy) {
      input_validation.terms_and_privacy = ["Please accept the terms and conditions"]
    }
    // card
    // this.props.setLoader(true, "Creating Payment Method...")
    // const {stripe, elements} = this.props;
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements.getElement(CardElement),
    // });
    // this.props.setLoader(false, null)
    // console.log(error)
    // console.log(paymentMethod)
    // if(error) {
    //   input_validation.card = [error.message]
    // }

    console.log('input_validation', input_validation)
    if(Object.keys(input_validation).length > 0) {
      if(show_address_fields) {
        this.setState({ show_address_fields: true })
        console.log(this.addressInput)
        if(this.addressInput) {
          this.addressInput.focus()
        }
      }      
      this.props.inputValidation(input_validation)
    } else {
      this.props.setLoader(true, "Registering...")
      this.props.checkEmailExists(formValues.email, () => {
        this.props.clearValidation()
        // setup data for ajax

        const names = formValues.fullname.trim().split(' ') 
        const firstname = names[0];
        names.splice(0, 1)
        const lastname = names.join(' ') 

        const region = this.state.regions.filter(v => {
          return v.id == formValues.state
        })

        let region_data = {}
        if(region.length > 0) {
          region_data = region[0]
        } else {
          console.log('Error getting region')
          return
        }

        getReCaptchaToken('login', (token) => {
          let data = {
            customer: {
              email: formValues.email,
              firstname: firstname,
              lastname: lastname,
              store_id: 19,
              website_id: 1,
              addresses: [
                {
                  region: {
                    region_code: region_data.code,
                    region: region_data.name,
                    region_id: region_data.id 
                  },
                  region_id: formValues.state,
                  country_id: formValues.country,
                  street: [
                    formValues.street_address
                  ],
                  telephone: formValues.mobile,
                  postcode: formValues.postcode,
                  city: formValues.suburb,
                  firstname: firstname,
                  lastname: lastname,
                  default_shipping: true,
                  default_billing: true
                }
              ] 
            },
            // data: JSON.stringify(paymentMethod),
            mobile: formValues.mobile,
            r_token: token,
            campaign_id: localStorage.campaign_id 
          }

          this.props.register(data, () => {
            this.props.setLoader(false, null)
            console.log('-----registered----')
            if(this.props.jwt) {
              console.log('this.props.jwt: '+this.props.jwt)
              localStorage.autologin = false
              history.push('/autologin/'+this.props.jwt)
            } else {
              history.push('/browse')
            }
          })
        })
      })


    }
    
  };

  renderInput = ({ input, label, type, defaultValue, readonly, id, addressCallback }) => { 
    let readonly_str = null;
    if(readonly) {
      readonly_str = "readonly"
    }
    return (
      <div>
        {/* <label>{label}</label> */}
        <input {...input} id={id} type={type} placeholder={label} readOnly={readonly_str} onKeyPress={(e) => {
          if(e.key === "Enter") {
            e.preventDefault();
          }
        }} ref={addressCallback}/>
      </div>
    );
  };

  renderSelect = ({ input, isCountries, isRegions, defaultValue }) => {
    let options = null
    if(isCountries) {
      options = [<option key="AU" value="AU" defaultValue={defaultValue}>Australia</option>,<option key="US" value="US" >United States of America</option>]
    } else if(isRegions) {
      options = [
        <option key="enter_state">Enter State</option>
      ]
      let region_options = []
      if(this.state) { 
        region_options = this.state.regions.map(region => {
          return (
            <option key={region.id} value={region.id}>
              {region.name}
            </option>
          );
        });
      }
      options = options.concat(region_options);
    }

    

    return (
      <div className="select-wrapper"> 
        <select {...input}>{options}</select> 
      </div>
    );
  };

  componentDidUpdate = () => {
    var autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('google-places-input'));
    autocomplete.addListener('place_changed', () => {
        var place = autocomplete.getPlace();
        console.log(place)

        if(place.address_components != undefined) {

          const address = {
            street_address: "", 
            suburb: "",
            state: "",
            country: "",
            postcode: ""
          }
          //       address: {
          //         street_address: "10 Bonita Court", 
          //         suburb: "Mudgeeraba",
          //         state: "QLD",
          //         country: "Australia",
          //         postcode: "4213"
          //       } 

          // set country
          place.address_components.forEach(function (value) {
              if (value.types.includes("country")) {
                  address.country = value.short_name
              }
          });

          // set street address
          var street_address = "";
          place.address_components.forEach(function (value) {
              if (value.types.includes("street_number")) {
                  street_address += value.long_name
              }
          });
          street_address += " ";
          place.address_components.forEach(function (value) {
              if (value.types.includes("route")) {
                  street_address += value.long_name
              }
          });
          address.street_address = street_address

          // set city
          place.address_components.forEach(function (value) {
              if (value.types.includes("locality")) {
                address.suburb = value.long_name 
              }

              if (value.types.includes("postal_town")) {
                address.suburb = value.long_name
              }

              if (value.types.includes("sublocality")) {
                address.suburb = value.long_name
              }
          });

          // set state
          place.address_components.forEach(function (value) {
              if (value.types.includes("administrative_area_level_1")) {
                  address.state = value.long_name
              }
          });

          place.address_components.forEach(function (value) {
              if (value.types.includes("postal_code")) {
                  address.postcode = value.long_name
              }
          });

          let customer = {}
          if(this.state.customer) {
            customer = this.state.customer;
            if (this.state.customer.address) {
              delete customer.address;
            }
          }
          customer.address = address;
          this.setState({ customer: customer })
          //       address: {
          //         street_address: "10 Bonita Court", 
          //         suburb: "Mudgeeraba",
          //         state: "QLD",
          //         country: "Australia",
          //         postcode: "4213"
          //       } 
          let region_id = null;
          this.state.regions.forEach((v) => {
            if(v.name === address.state) {
              region_id = v.id
            }
          })
          this.props.change('address', place.formatted_address);
          const country_id = address.country;//"AU"
          this.props.change('street_address', address.street_address);
          this.props.change('suburb', address.suburb);
          this.props.change('state', region_id);
          this.props.change('country', country_id);
          this.props.change('postcode', address.postcode);
      } else {
          // show inputs
      }
    })
  }

  // setOutcome = (event) => {
  //   var displayError = document.getElementById('card-errors');
  //   if (event.error) {
  //       displayError.textContent = event.error.message;
  //   } else {
  //       displayError.textContent = '';
  //   }
  // }

  render() {
    // const {stripe} = this.props;

    let mobileMessage = this.props.input_validation.mobile.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    let fullnameMessage = this.props.input_validation.fullname.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    let emailMessage = this.props.input_validation.email.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    let addressMessage = this.props.input_validation.address.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    let streetAddressMessage = this.props.input_validation.street_address.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    let suburbMessage = this.props.input_validation.suburb.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    let stateMessage = this.props.input_validation.state.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    let countryMessage = this.props.input_validation.country.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    let postcodeMessage = this.props.input_validation.postcode.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    // let cardMessage = this.props.input_validation.card.map(message => 
    //   <div key={message} className="input-validation">{message}</div>);
    let termsAndPrivacyMessage = this.props.input_validation.terms_and_privacy.map(message => 
      <div key={message} className="input-validation">{message}</div>);
    let registerMessage = this.props.input_validation.register.map(message => 
      <div key={message} className="input-validation">{message}</div>);

    // header section
    let header_section = null;
    if(this.props.customer && this.props.customer.shop_image && this.props.product) {

      // product image
      let product_image = "https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/Magento_Catalog/images/product/placeholder/thumbnail.jpg"
      let product_seller_image = this.props.customer.shop_image

      if(this.props.product) {
        if(this.props.product.images.length > 0) {
          product_image = this.props.product.images[0]
        }
  
        if(this.props.product.product_seller_image) {
          product_seller_image = this.props.product.product_seller_image
        }
      }

      header_section = (
        <div id="registration-header-top-section" className="with-shop-image">
          <div id="registration-top-left">
            <div id="registration-shop-image-parent">
              <img src={product_seller_image} />
            </div>
            <div className="input-box">
              <Field
                name="mobile"
                component={this.renderInput}
                label="Mobile"
                type="text"
                readonly={true}
              />
              {mobileMessage}
            </div> 
            <div className="input-box">
              <Field
                name="fullname"
                component={this.renderInput}
                label="Fullname"
                type="text"
              />
              {fullnameMessage}
            </div>
          </div>
          <div id="registration-top-right">
            <div id="registration-product-wrapper">
              <img src={product_image}/> 
            </div>
          </div>
        </div>
      )
    } else {
      header_section = (
        <React.Fragment>
          <div id="registration-header-top-section">
            <h2 id="heading-registration">Registering with SecureMobile</h2>
          </div>
          <div className="input-box"> 
          <Field
            name="mobile"
            component={this.renderInput}
            label="Mobile"
            type="text"
            readonly={true}
          />
          {mobileMessage}
        </div>
        <div className="input-box">
          <Field
            name="fullname"
            component={this.renderInput}
            label="Fullname"
            type="text"
          />
          {fullnameMessage}
        </div>
      </React.Fragment>
      )
    }

    let seller_name = null
    if(this.props.seller) {
      seller_name = this.props.seller.name
    }

    // // product section
    // let product_section = null
    // if(this.props.product) {
    //   let product_image = "https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/Magento_Catalog/images/product/placeholder/thumbnail.jpg"
    //   if(this.props.product.images.length > 0) {
    //     product_image = this.props.product.images[0]
    //   }

    //   product_section = (
    //     <div id="registration-product-right">
    //       <div id="registration-product-wrapper">
    //         <img src={product_image}/>
    //       </div>
    //     </div>
    //   )
    // }


    return (
        <div id="page-registration"> 
          <form
            onSubmit={this.props.handleSubmit(this.handleSubmit)}
            className="form"
            id="registration-form" 
          >
            {header_section}
            <div className="input-box">
              <Field
                name="email"
                component={this.renderInput}
                label="Email"
                type="text"
              />
              {emailMessage}
            </div>
            <div className="input-box">
              <Field
                name="address"
                component={this.renderInput}
                label="Type Address..."
                type="text"
                id="google-places-input"
                onKeyPress={e => {
                  console.log('in here7878')
                  if (e.key === 'Enter') e.preventDefault();
                }} 
                addressCallback={(input) => {
                  console.log('input', input) 
                  this.addressInput = input 
                }}
              />
              {addressMessage}
            </div>
            {this.state.show_address_fields && <div>
              <div className="input-box">
                <Field
                  name="street_address"
                  component={this.renderInput}
                  label="Enter Street Address"
                  type="text"
                />
                {streetAddressMessage}
              </div>
              <div className="input-box">
                <Field
                  name="suburb"
                  component={this.renderInput}
                  label="Enter Suburb"
                  type="text"
                />
                {suburbMessage}
              </div>
              <div className="input-box">
                <Field
                  name="state"
                  component={this.renderSelect}
                  label="Enter State"
                  type="text"
                  isRegions={true}
                  value="569"
                />
                {stateMessage}
              </div>
              <div className="input-box">
                <Field
                  name="country"
                  component={this.renderSelect}
                  label="Enter Country"
                  type="text"
                  isCountries={true}
                  value="AU" 
                />
                {countryMessage}
              </div>
              <div className="input-box">
                <Field
                  name="postcode"
                  component={this.renderInput}
                  label="Enter Postcode"
                  type="text"
                />
                {postcodeMessage}
              </div>
            </div>}
            <div className="line-break"></div>
            {/* <div id="registration-payment-method-heading">Payment Method</div>
            <div id="registration-card-element">
              <div className="input-box">
                <CardElement 
                  options={{
                    style: {
                      base: {
                        fontSize: '16px'
                      },
                    },
                  }}
                />
              </div>
              {cardMessage} 
            </div>
            <div className="line-break"></div> */}
            <div id="terms-privacy">
              <div id="agreement-checkbox">
                <Field
                  name="termsAndPrivacy"
                  component={this.renderInput}
                  label="Tick Terms and Privacy"
                  type="checkbox"
                />
              </div>
              <div id="agreement-text">By registering a SecureMobile Account {isTap2Buy() ? 'Tap2Buy' : isGiftGuru() ? 'Tap2Gift' : 'Tap2Buy'} using Create Account you agree to our <Link to="/terms">Terms</Link> and <Link to="/privacy">Privacy Policy</Link></div>
            </div>
            {termsAndPrivacyMessage}
            {registerMessage}
            <button id="registration-button" type="submit">Register</button> 
          </form>
          {this.props.seller && <div id="registration-display-container" className={`${this.state.show_registration_popup ? '' : 'hide-registration-display' }`}>
            <div id="registration-display">
              <div id="display-image-parent">
                <div id="display-image">{isTap2Buy() ? 'Tap2Buy' : isGiftGuru() ? 'Tap2Gift' : 'Tap2Buy'}</div>
              </div>
              <div id="display-seller">{seller_name} is authorised as a Tap2Gift Partner.</div>
              <div id="display-desc-container">
                <div className="display-desc-text">One time only registration.</div>
                <div className="display-desc-text">Purchase {seller_name} Purchase {isTap2Buy() ? 'Tap2Buy' : isGiftGuru() ? 'Tap2Gift Cards' : 'Tap2Buy'}  and other vendors products through the SecureMobile one click purchase option.</div>
                <div className="display-desc-text">No username, passwords or logins.</div>
              </div>
              <div id="display-button-parent">
                <button id="display-button" onClick={() => {
                  this.setState({ show_registration_popup: false })
                }}>Got it</button>
              </div>
              <div id="display-footer">
                <div id="display-footer-protected">Protected by:</div>
                <div id="display-footer-image-parent">
                  <a href="https://www.securemobile.net" ><img id="display-footer-image" src="https://cdn-staging.securemobile.net/web/images/securemobile-logo-blue-white-transparent.png" /></a>
                </div>
              </div>
            </div>
          </div>}
          {!this.state.show_registration_popup && <div id="registration-display-show-more">
            <div className="show-more-arrow" onClick={() => { 
              this.setState({ show_registration_popup: true })
            }}></div> 
          </div>}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    customer: state.customer.customer,
    input_validation: state.input_validation,
    jwt: state.jwt,
    product: state.product.product,
    seller: state.seller.seller,
    form_values: getFormValues('registration')(state),
  };
};

const registrationPage = reduxForm({
  form: "registration"
})(RegistrationPage); 

const ReadyRegistrationPage = requireNoAuth(
  connect(
    mapStateToProps,
    { register, inputValidation, clearValidation, checkEmailExists, setLoader, fetchProduct, fetchSeller, setCustomerData } 
  )(registrationPage)
);

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <ReadyRegistrationPage stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

let pk_stripe = null
if(window.location.origin === 'https://www.tap2buy.com' || window.location.origin === 'https://live-giftguru.dnrx1u9c16dvg.amplifyapp.com') {
  pk_stripe = "pk_live_RQdsNdxOG0IYGefPnPF14tFY"; //"pk_live_RQdsNdxOG0IYGefPnPF14tFY"
} else {
  pk_stripe = 'pk_test_rnMv6e5UnHRP3LkC8dW6TyZr00hBMZIlvn'
}

const stripePromise = loadStripe(pk_stripe); 

const ReadyRegistration = () => (
  <Elements stripe={stripePromise}>
    <InjectedCheckoutForm />
  </Elements>
);

export default ReadyRegistration;
